import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const HighwayTrucks = () => {
  let pageTitle = "Highway Trucks";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Highway Traffic Management truck Hire Melbourne"
          description="Mr Traffic Equipment Hire. Melbourne's leading Highway Traffic Management truck Hire"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/highway-truck.jpg"
            alt="Highway Truck Hire"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>
              Suppliment your fleet with our Highway Traffic Management trucks
            </h5>
            <p>
              We have a stock of late model trucks ready for use. All are
              equiped with sign racks, warning lights and arrow boards to meet
              all your clients expectations.
            </p>
            <p className="mt-3">
              <h5>Key Features</h5>

              <ul className="mt-3">
                <li>Fully maintained and safety checked</li>
                <li>Car license rated</li>
                <li>B Clasee roof mounted Arrow Board</li>
                <li>Warning lights</li>
                <li>VMS board</li>
                <li>Drop deck area for employee safety</li>
                <li>Tow bar</li>
                <li>Sign rack</li>
                <li>GPS tracking</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default HighwayTrucks;

import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const TrafficUtes = () => {
  let pageTitle = "Traffic Utes";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Traffic Management Ute Hire Melbourne"
          description="Melbourne's leading Traffic Management Ute Hire. Traffic Management utes ready for use"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/traffic-utes.jpg"
            alt="Traffic Management ute hire"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>Suppliment your fleet with our Traffic Management utes</h5>
            <p>
              We have a stock of late model Traffic Management utes ready for
              use. All are equipped with sign racks, warning lights and arrow
              boards to meet all your clients expectations.
            </p>
            <p className="mt-3">
              <h5>Key Features</h5>

              <ul className="mt-3">
                <li>Fully maintained and safety checked</li>
                <li>B Class roof mounted Arrow Board</li>
                <li>Warning lights</li>
                <li>Tow bar</li>
                <li>Sign rack</li>
                <li>GPS tracking</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default TrafficUtes;

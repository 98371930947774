import ActionTypes from "./action-types";
import QuoteService from "./../services/service";
import {
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";
let service = QuoteService();

export const getQuote = (data) => (dispatch) => {
  dispatch(getQuoteRequest());
  service
    .getQuote(data)
    .then(() => {
      dispatch(
        addNotification("Your quote request has been successfully sent.")
      );
      dispatch(getQuoteSuccess());
    })
    .catch((error) => {
      dispatch(
        addErrorNotification(error, "Unable to send your quote request.")
      );
      dispatch(getQuoteFailure(error));
    });
};

const getQuoteRequest = () => ({
  type: ActionTypes.GET_QUOTE_REQUEST,
});

const getQuoteSuccess = () => ({
  type: ActionTypes.GET_QUOTE_SUCCESS,
});

const getQuoteFailure = (error) => ({
  type: ActionTypes.GET_QUOTE_FAILURE,
  payload: error,
});

export const cleanUp = () => ({
  type: ActionTypes.GET_QUOTE_CLEAN_UP,
});

import React, { useEffect } from "react";
import CollapsibleItem from "components/common/collapsible-panel/collapsible-item";
import SectionNavigation from "components/common/collapsible-panel/section-navigation";
import { Col, Row, Card, Label } from "reactstrap";
import SignaturePad from "components/common/signature-pad/signature-pad";
import CommonFastField from "components/common/common-fast-field";
import { FieldArray } from "formik";
import shallowequal from "shallowequal";
import ActualTerms from "./actual-terms/actual-terms";
import Guarantee from "./actual-terms/guarantee";
import moment from "moment";

const TermsAndConditions = ({
  handleInit,
  collapseState,
  handleToggle,
  idsRegistered,
  setToggle,
  setToggleAll,
  setFieldValue,
  values,
}) => {
  useEffect(() => {
    setFieldValue(
      "section4.signatures",
      values.section2.persons.map((x) => {
        return {
          id: x.id,
          name: x.name,
          specimen: "",
        };
      })
    );

    setFieldValue(
      "section4.guarantors",
      values.section2.persons.map((x) => {
        return {
          id: x.id,
          name: x.name,
          specimen: "",
        };
      })
    );
  }, [values.section2.persons]);

  return (
    <CollapsibleItem
      id="section4"
      key="4"
      title="Credit Terms and Conditions"
      init={handleInit}
      collapseState={collapseState}
      toggle={handleToggle}
    >
      <Row>
        <Col xs="12" lg="9">
          <div className="mb-3">
            <ActualTerms />
          </div>
          <div className="check-box">
            <CommonFastField
              label="I confirm that I have read and understood the above terms and conditions."
              accessor="section4.agreeTerms"
              showLabel
              type="checkbox"
            />
          </div>

          <Label className="font-weight-bold">Signatures</Label>

          <Card body className="mb-3">
            <FieldArray
              name="section4.signatures"
              render={() => (
                <div className="mb-3">
                  {values.section4.signatures &&
                    values.section4.signatures.length > 0 && (
                      <>
                        {values.section4.signatures.map((signature, index) => (
                          <div className="mb-3" key={index}>
                            <h5>
                              Person {index + 1}
                              {values.section4.signatures[index].name && (
                                <>
                                  <span className="ml-1 font-weight-bold">
                                    ({values.section4.signatures[index].name}
                                    <span className="ml-1">
                                      - {moment().format("DD/MM/YYYY")}
                                    </span>
                                    )
                                  </span>
                                </>
                              )}
                            </h5>

                            <SignaturePad
                              id={`signature-${index}`}
                              isReadonly={false}
                              onChange={(value) =>
                                setFieldValue(
                                  `section4.signatures.${index}.specimen`,
                                  value
                                )
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                </div>
              )}
            />
          </Card>

          {values.section1.customerType &&
            values.section1.customerType.value === 0 && (
              <div className="mb-3">
                <div className="mb-3">
                  <Guarantee />
                </div>

                <Card body>
                  <FieldArray
                    name="section4.signatures"
                    render={() => (
                      <div className="mb-3">
                        {values.section4.guarantors &&
                          values.section4.guarantors.length > 0 && (
                            <>
                              {values.section4.guarantors.map(
                                (guarantor, index) => (
                                  <div className="mb-3" key={index}>
                                    <h5>
                                      Person {index + 1}
                                      {values.section4.guarantors[index]
                                        .name && (
                                        <span className="ml-1 font-weight-bold">
                                          (
                                          {
                                            values.section4.guarantors[index]
                                              .name
                                          }
                                          <span className="ml-1">
                                            - {moment().format("DD/MM/YYYY")}
                                          </span>
                                          )
                                        </span>
                                      )}
                                    </h5>

                                    <SignaturePad
                                      id={`guarantee-${index}`}
                                      isReadonly={false}
                                      onChange={(value) =>
                                        setFieldValue(
                                          `section4.guarantors.${index}.specimen`,
                                          value
                                        )
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </>
                          )}
                      </div>
                    )}
                  />
                </Card>
              </div>
            )}

          <SectionNavigation
            sectionId="section4"
            idsRegistered={idsRegistered}
            setToggle={setToggle}
            setToggleAll={setToggleAll}
          />
        </Col>
      </Row>
    </CollapsibleItem>
  );
};

function valuesAreEqual(prevProps, nextProps) {
  return (
    shallowequal(
      prevProps.values.section1.customerType,
      nextProps.values.section1.customerType
    ) &&
    shallowequal(prevProps.values.section4, nextProps.values.section4) &&
    shallowequal(
      prevProps.values.section2.persons,
      nextProps.values.section2.persons
    ) &&
    prevProps.collapseState.section4 === nextProps.collapseState.section4
  );
}

export default React.memo(TermsAndConditions, valuesAreEqual);

import { useEffect } from "react";

const useQuoteHandler = (isSuccessful, handleReset, cleanUp) => {
  useEffect(() => {
    cleanUp();

    setDefaultValues();
  }, []);

  useEffect(() => {
    if (isSuccessful) {
      handleReset();

      setDefaultValues();
    }
  }, [isSuccessful]);

  const setDefaultValues = () => {};
};

export default useQuoteHandler;

import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const PortableTrafficLights = () => {
  let pageTitle = "Portable Traffic Lights";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Mr Traffic Equipment Hire | Portable Traffic Light Hire Melbourne"
          description="Mr Traffic Equipment Hire. Melbourne's leading Portable Traffic Light Hire"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/portable-traffic-lights.jpg"
            alt="Portable Traffic Light trailer hire"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>Portable Traffic Lights - Safety in Technology</h5>
            <p>
              Our Portable Traffic Light includes the latest technology
              enhancements within the traffic light controller, which now allows
              customers complete on-site control as well as remote control via
              the internet to minimise cost, risk and downtime.
            </p>
            <p>
              Our Portable Traffic Light can be linked with other BARTCO
              PTL&rsquo;s to allow multiway intersection control.
            </p>
            <p>
              They are designed around a sturdy but light weight solar powered
              dual trailer system, quick and easy to setup and operate.
            </p>
            <p>
              They are designed for high-visibility signalling in applications
              ranging from work zones and bridge repair to special events and
              emergency backup.
            </p>
            <p>
              Whatever your application, you can be sure that our traffic
              equipment will have your needs covered. BARTCO Portable Solar
              Traffic Signals are the most advanced and reliable portable
              traffic signal system to the industry today.
            </p>

            <p className="mt-3">
              <h5>Features</h5>

              <ul className="mt-3">
                <li>Easy operation</li>
                <li>Multi-way Intersection capable</li>
                <li>Anti theft and tamper protection</li>
                <li>Solar pwered / Battery operated</li>
                <li>Dual trailer system for 2 lights</li>
                <li>GPS tracking</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default PortableTrafficLights;

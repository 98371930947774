import ManageUsers from "./../views/admin/manage-users";
import AdminView from "./../views/admin/admin-view";
import CreateUpdateUserPage from "./../views/admin/manage-users/components/create-update-user";

const AdminRoutes = [
  {
    path: "/admin/",
    name: "Admin",
    component: AdminView,
    privateRoute: true,
  },
  {
    path: "/admin/manage-users/",
    name: "Manage Users",
    component: ManageUsers,
    privateRoute: true,
  },
  {
    path: "/admin/create-user/",
    name: "Create User",
    component: CreateUpdateUserPage,
    privateRoute: true,
  },
  {
    path: "/admin/update-user/:id",
    name: "Update User",
    component: CreateUpdateUserPage,
    privateRoute: true,
  },
];

export default AdminRoutes;

import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const VmsBoards = () => {
  let pageTitle = "VMS Boards";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Mr Traffic Equipment Hire | VMS Board Hire Melbourne"
          description="Mr Traffic Equipment Hire. Melbourne's leading Variable Message Sign Hire | VMS Board Hire"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/vms-boards.jpg"
            alt="Variable Message Boards VMS Boards"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>
              Our VMS come in 3 sizes and can be used for a broad range of
              requirements.
            </h5>
            <p>
              They are battery operated as well as solar powered, a convenient
              energy-saving option for industries within the road and traffic
              management sector and customers who are simply looking for a
              solution for your advertising needs.
            </p>
            <p>Our boards meet the requirements of AS4191.</p>
            <p className="mt-3">
              <h5>Our VMS can be used for the following;</h5>

              <ul className="mt-3">
                <li>Road safety messages</li>
                <li>Real-time traffic information relating to incidents</li>
                <li>Roadworks / Travel times</li>
                <li>Speed limit warning</li>
                <li>Detected speed limit advise (using radar)</li>
                <li>Sales / Promotional campaign</li>
                <li>General message display</li>
                <li>Events and Festivals</li>
                <li>Corporate and Private functions</li>
                <li>GPS tracking</li>
              </ul>
            </p>
            <p>
              Our VMS are programmed via BARTCO's VMS WEB STUDIO real-time
              internet based communication facility, enabling live communication
              via cellular or satellite networks from any internet enabled PC,
              Laptop, Tablet or Mobile, eliminating the need for the
              installation of local software.
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default VmsBoards;

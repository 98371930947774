import React from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
} from "reactstrap";
import FilterButton from "../filter-pane/filter-button/filter-button";
import FilterContainer from "../filter-pane/filter-container/filter-container";
import "../filter-pane/filter-pane.css";

/**
 * FilterPane - functional component
 * - Renderer filter components
 * @param {props} functional component props provided by datatable
 */
const FilterPane = ({ ...props }) => (
  <FilterContainer>
    {props.filterOptions.filters.map((item, i) => {
      return ComponentFactory().get(item, i, props.onFilterChange);
    })}
    <FilterButton {...props} />
  </FilterContainer>
);

/**
 * Component Factory
 * Add input components here
 */
const ComponentFactory = () => {
  const get = (item, key, onFilterChange) => {
    switch (item.type.toLowerCase()) {
      case "textbox":
        return _getTextbox(item, key, onFilterChange);
      case "checkbox":
        return _getCheckbox(item, key, onFilterChange);
      case "dropdown":
        return _getDropDown(item, key, onFilterChange);
      default:
        return null;
    }
  };

  const _getTextbox = (item, key, onFilterChange) => (
    <InputGroup key={key} className="input-group-filter-pane">
      {item.iconPrepend ? (
        <InputGroupAddon addonType="prepend">
          {item.iconPrepend}
        </InputGroupAddon>
      ) : null}
      <Input
        key={key}
        placeholder={item.label}
        onChange={(evt) =>
          onFilterChange({
            data: { ...item, value: evt.target.value },
            event: evt,
          })
        }
      />
    </InputGroup>
  );

  const _getCheckbox = (item, key, onFilterChange) => (
    <FormGroup key={key} className="input-group-filter-pane" check>
      <Label check>
        <Input
          type="checkbox"
          key={key}
          aria-label={"Filter checkbox " + { key }}
          onChange={(evt) =>
            onFilterChange({
              data: { ...item, value: evt.target.checked },
              event: evt,
            })
          }
        />{" "}
        {item.label}
      </Label>
    </FormGroup>
  );

  const _getDropDown = (item, key, onFilterChange) => (
    <FormGroup key={key}>
      {/* <Label for={"select" + { key }}>Select</Label> */}
      <Input
        type="select"
        name="select"
        key={key}
        onChange={(evt) =>
          onFilterChange({
            data: { ...item, value: evt.target.value },
            event: evt,
          })
        }
      >
        {item.items.map((opt, i) => (
          <option value={opt.id} key={i}>
            {opt.value}
          </option>
        ))}
      </Input>
    </FormGroup>
  );

  return {
    get,
  };
};

export default FilterPane;

import * as Yup from "yup";

const CustomerInformationValidationSchema = Yup.object().shape({
  personCompletingTheApplication: Yup.string()
    .required("Name of person completing this application is required.")
    .min(2, "Name of person completing this application is too short.")
    .max(50, "Name of person completing this application is too long."),
  position: Yup.string().required("Position is required."),
  customerType: Yup.string().required("Customer type is required."),
  fullNameOfCustomer: Yup.string()
    .required("Customer's full name is required.")
    .min(2, "Customer's full name is too short.")
    .max(50, "Customer's full name is too long."),
  businessName: Yup.string().required("Trading or business Name is required."),
  acn: Yup.string().when("customerTypeValue", {
    is: (val) => {
      return val === 0 || val === 1;
    },
    then: Yup.string().required("ACN is required."),
    otherWise: Yup.string().notRequired(),
  }),
  abn: Yup.string()
    .min(14, "Invalid australian business number format.")
    .required("Australian business number is required."),
  phone: Yup.string().required("Phone is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  customerTradingLength: Yup.string().required(
    "Please indicate how long has customer been trading."
  ),
  businessAddress: Yup.object().shape({
    unitNumber: Yup.string(),
    streetNumber: Yup.string().required("Street number is required."),
    streetName: Yup.string().required("Street name is required."),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    postcode: Yup.string().required("Post code is required."),
  }),
  postalAddress: Yup.object().when("sameAddresses", {
    is: (val) => !val,
    then: Yup.object().shape({
      unitNumber: Yup.string(),
      streetNumber: Yup.string().required("Street number is required."),
      streetName: Yup.string().required("Street name is required."),
      city: Yup.string().required("City is required."),
      state: Yup.string().required("State is required."),
      postcode: Yup.string().required("Post code is required."),
    }),
  }),
  trustDeed: Yup.array().when("customerTypeValue", {
    is: (val) => val === 4,
    then: Yup.array()
      .min(1, "Please add at least one file.")
      .required("Please add at least one file."),
  }),
});

export default CustomerInformationValidationSchema;

import { ActionTypes } from "./action-types";
import HttpService from "../services/service";
import Auth from "./../../../core/auth/auth";
import UserIdentity from "./../../../core/auth/userIdentity";
import {
  addNotification,
  setProfile,
} from "./../../../redux/system/system-action-creators";
import ProfileService from "./../../../services/profile-service";

const httpService = HttpService();
const profileService = ProfileService();

export const changePassword = (password, confirmPassword, resetToken) => (
  dispatch
) => {
  dispatch(changePasswordRequest());
  httpService
    .changePassword(password, confirmPassword, resetToken)
    .then(({ data }) => {
      Auth.removeToken();
      Auth.setToken(data.access_token, data.refresh_token, data.expires_in);
      const userIdent = UserIdentity();
      profileService
        .getProfile(userIdent.getUserId())
        .then(({ data }) => {
          dispatch(setProfile(data));
          dispatch(changePasswordSuccess());
        })
        .catch(() => {
          dispatch(
            addNotification("Unable to fetch the user profile.", "error")
          );
        });
    })
    .catch(() => {
      dispatch(changePasswordFailure());
    });
};

const changePasswordRequest = () => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST,
});

const changePasswordSuccess = () => ({
  type: ActionTypes.RESET_PASSWORD_SUCCESS,
});

const changePasswordFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.RESET_PASSWORD_FAILURE });
  dispatch(addNotification("Account does not exist.", "error"));
};

export const cleanUp = () => ({
  type: ActionTypes.RESET_PASSWORD_CLEAN_UP,
});

export const validateResetToken = (resetToken) => (dispatch) => {
  dispatch(validateResetTokenRequest());
  httpService
    .validateResetToken(resetToken)
    .then(() => dispatch(validateResetTokenSuccess(resetToken)))
    .catch(() => dispatch(validateResetTokenFailure()));
};

const validateResetTokenRequest = () => ({
  type: ActionTypes.VALIDATE_PASSWORD_RESET_TOKEN_REQUEST,
});

const validateResetTokenSuccess = (resetToken) => ({
  type: ActionTypes.VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS,
  payload: resetToken,
});

const validateResetTokenFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.VALIDATE_PASSWORD_RESET_TOKEN_FAILURE });
};

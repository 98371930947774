const TermsAndConditionsInitialValues = {
  agreeTerms: false,
  signatures: [
    {
      id: "",
      name: "",
      specimen: "",
    },
  ],
  guarantors: [
    {
      id: "",
      name: "",
      specimen: "",
    },
  ],
};

export default TermsAndConditionsInitialValues;

import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must have at least 2 characters.")
    .max(30, "Name is too long.")
    .required("Name is required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required."),
  phone: Yup.string().min(10, "Invalid phone number."),
  services: Yup.array()
    .of(Yup.string().required("Please select at least 1 service."))
    .required("Please select at least 1 service."),
  source: Yup.array()
    .of(Yup.string().required("Please select at least 1 item."))
    .required("Please select at least 1 item."),
  message: Yup.string()
    .required("Message is required.")
    .matches(/^\s*([^\s]+\s*){1,500}$/, "Message should not exceed 500 Words."),
});

export default validationSchema;

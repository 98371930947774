import React from "react";
import ContentSection from "components/common/content-section";
import Citiwests from "components/common/citiwests";
import "./citiwest.scss";

const Citiwest = () => {
  return (
    <div className="citiwest">
      <ContentSection bgClass="sw-bg-std-grey">
        <div className="w-100 text-center">
          <h2 className="mb-4">Traffic Equipment Hire Specialists</h2>
          <strong>Traffic Equipment hire on any scale</strong>
          <Citiwests />
        </div>
      </ContentSection>
    </div>
  );
};

export default Citiwest;

const getImage = (file) => {
  return `url(/images/${file})`;
};

const items = [
  {
    id: 1,
    title: "Arrow Boards",
    image: getImage("arrow-board.jpg"),
    link: "/services/arrow-boards/",
  },
  {
    id: 2,
    title: "VMS Boards",
    image: getImage("vms-boards.jpg"),
    link: "/services/vms-boards/",
  },
  {
    id: 3,
    title: "Portable Traffic Lights",
    image: getImage("portable-traffic-lights.jpg"),
    link: "/services/portable-traffic-lights/",
  },
  {
    id: 4,
    title: "Travel Times - Data Collection",
    image: getImage("travel-times.jpeg"),
    link: "/services/travel-times/",
  },
  {
    id: 5,
    title: "Variable Speed Limit Signs",
    image: getImage("variable-speed-limit-signs.jpg"),
    link: "/services/variable-speed-limit-signs/",
  },
  {
    id: 6,
    title: "Truck Mounted Attenuators",
    image: getImage("truck-mounted-attenuators.jpg"),
    link: "/services/truck-mounted-attenuators/",
  },
  {
    id: 7,
    title: "Highway Trucks",
    image: getImage("highway-truck.jpg"),
    link: "/services/highway-trucks/",
  },
  {
    id: 8,
    title: "Traffic Utes",
    image: getImage("traffic-utes.jpg"),
    link: "/services/traffic-utes/",
  },
  {
    id: 9,
    title: "VMS Utes",
    image: getImage("vms-ute.jpg"),
    link: "/services/vms-utes/",
  },
  {
    id: 10,
    title: "Portaboom",
    image: getImage("portaboom.jpg"),
    link: "/services/portaboom/",
  },
];

export default items;

// Reqired props :
// - label - Label
// - accessor - Formik field accessor
// - showLabel - Option to show field label
// - mask - Mask

import React from "react";
import { FormGroup, Label } from "reactstrap";
import CustomErrorMessage from "./../error-message";
import { Field } from "formik";
import InputMask from "react-input-mask";

const setValue = (accessor, value, setValueFunc) =>
  setValueFunc(accessor, value.replace(/\s/g, ""));

const MaskedInput = (props) => {
  const { label, accessor, showLabel, mask, overridePlaceholder } = props;
  return (
    <FormGroup>
      {showLabel && <Label className="font-weight-bold">{label}</Label>}

      <Field name={accessor}>
        {({ field, meta, form }) => (
          <div>
            <FormGroup>
              <InputMask
                placeholder={!overridePlaceholder && label}
                className="form-control"
                style={{backgroundColor: "#F5F5F7"}}
                mask={mask}
                value={field.value}
                onChange={(event) =>
                  setValue(accessor, event.target.value, form.setFieldValue)
                }
                onFocus={() => form.setFieldTouched(accessor, true)}
                onBlur={(event) =>
                  setValue(accessor, event.target.value, form.setFieldValue)
                }
                {...field}
                maskChar={null}
              />
            </FormGroup>
            <div style={meta.error ? { marginTop: "-8px" } : {}}>
              <CustomErrorMessage touched={meta.touched} error={meta.error} />
            </div>
          </div>
        )}
      </Field>
    </FormGroup>
  );
};

export default MaskedInput;

import moment from "moment";
import isNil from "lodash/isNil";

export const toIsoDate = (date) => {
  let dt = moment.isMoment(date) ? date.toDate() : date;

  return isNil(dt) ||
    moment(dt).format("DD/MM/YYYY").toUpperCase() === "01 JAN 1900"
    ? ""
    : moment(dt).format("YYYY-MM-DD HH:mm:ss").toUpperCase();
};

import * as Yup from "yup";

const DetailsValidationSchema = Yup.object().shape({
  persons: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .min(2, "Name is too short.")
          .required("Name is required."),
        phone: Yup.string().required("Phone is required."),
        email: Yup.string()
          .email("Invalid email address.")
          .required("Email is required."),
        address: Yup.object().shape({
          unitNumber: Yup.string(),
          streetNumber: Yup.string().required("Street number is required."),
          streetName: Yup.string().required("Street name is required."),
          city: Yup.string().required("City is required."),
          state: Yup.string().required("State is required."),
          postcode: Yup.string().required("Post code is required."),
        }),
        driversLicence: Yup.string()
          .min(2, "Driver's licence is too short.")
          .required("Driver's licence is required."),
      })
    )
    .min(1, "Contact person is required.")
    .required("Contact person is required."),
});

export default DetailsValidationSchema;

const states = [
  {
    value: "ACT",
    label: "Australia Capital Territory"
  },
  {
    value: "NSW",
    label: "New South Wales"
  },
  {
    value: "NT",
    label: "Northern Territory"
  },
  {
    value: "QLD",
    label: "Queensland"
  },
  {
    value: "SA",
    label: "South Australia"
  },
  {
    value: "TAS",
    label: "Tasmania"
  },
  {
    value: "VIC",
    label: "Victoria"
  },
  {
    value: "WA",
    label: "Western Australia"
  }
];

export default states;

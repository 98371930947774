import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const ArrowBoards = () => {
  let pageTitle = "Arrow Boards";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Mr Traffic Equipment Hire | Arrow Board Hire Melbourne"
          description="Mr Traffic Equipment Hire. Melbourne's leading Arrow Board Sign Hire"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/arrow-board.jpg"
            alt="Arrowboard Trailer Hire"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>Arrow Boards show you the way</h5>
            <p>
              Our portable Arrow Board is Ideal for a multitude of applications
              from long term lane closures to changing traffic management
              situations at work zones, construction and mining sites, among
              other site applications.
            </p>
            <p>
              Our portable directional arrow boards are solar powered, have high
              bright visibility and low consuming LED lanterns.
            </p>

            <p className="mt-3">
              <h5>Meets all requirements of AS4191</h5>

              <ul className="mt-3">
                <li>User friendly touchpad for easy programming</li>
                <li>Anti theft and tamper protection</li>
                <li>Easy towing</li>
                <li>360° Rotatable Display</li>
                <li>Automatic brightness control</li>
                <li>Multiple Light Configurations</li>
                <li>GPS tracking</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default ArrowBoards;

import React from "react";
import "./title-header.scss";
import { PropTypes } from "prop-types";
import { Container } from "reactstrap";

const TitleHeader = ({ title }) => {
  return (
    <header className="title-header d-flex align-items-center">
      <Container>
        <h1 className="shadow-md">{title}</h1>
      </Container>
    </header>
  );
};

TitleHeader.propTypes = {
  title: PropTypes.string,
};

export default TitleHeader;

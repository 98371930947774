import React from "react";

const Guarantee = () => {
  return (
    <>
      <div className="font-weight-bold text-uppercase mt-3">
        GUARANTEE AND INDEMNITY
      </div>

      <div className="font-weight-bold text-uppercase mt-3 text-justify">
        IF YOUR COMPANY IS NOT PUBLICLY LISTED THE DIRECTORS MUST SIGN AND
        GUARANTEE THE INDEMNITY.
      </div>

      <div className="mt-3 text-justify">
        I/we, being the directors of the Customer unconditionally guarantee the
        performance of the Company with regards to the repayments of its debt to
        Mr Traffic Hire pursuant to the details of this agreement. If the
        Company fails to make any payment to Mr Traffic Hire or fails to perform
        any of its obligations under the agreement, I/we will make all payments
        to Mr Traffic Hire as if I/we were the principals of the Agreement.
      </div>
    </>
  );
};

export default Guarantee;

import React, { useEffect } from "react";
import CollapsibleItem from "components/common/collapsible-panel/collapsible-item";
import CommonFastField from "components/common/common-fast-field";
import SectionNavigation from "components/common/collapsible-panel/section-navigation";
import { Col, Row, Card } from "reactstrap";
import GoogleAddressField from "components/common/google-address-field";
import { FieldArray } from "formik";
import { Link } from "react-router-dom";
import shallowequal from "shallowequal";
import generateUuid from "core/utilities/guid-generator";

const Details = ({
  handleInit,
  collapseState,
  handleToggle,
  idsRegistered,
  setToggle,
  setToggleAll,
  values,
  setFieldValue,
}) => {
  useEffect(() => {
    if (
      values.section1.customerType &&
      values.section1.customerType.value === 3 &&
      values.section2.persons.length > 1
    ) {
      setFieldValue("section2.persons", [values.section2.persons[0]]);
    }
  }, [values.section1.customerType]);

  const getTitle = (customerType) => {
    if (customerType) {
      switch (customerType.value) {
        case 0:
        case 1:
        case 4:
          return "Details of the Director(s)";
        case 2:
          return "Details of the Partner(s)";
        default:
          return "Details of the Sole Trader";
      }
    }

    return "Details";
  };

  return (
    <CollapsibleItem
      id="section2"
      key="2"
      title={getTitle(values.section1.customerType)}
      init={handleInit}
      collapseState={collapseState}
      toggle={handleToggle}
    >
      <Row>
        <Col xs="12" lg="9">
          <FieldArray
            name="section2.persons"
            render={(arrayHelpers) => (
              <div className="mb-3">
                {values.section2.persons && values.section2.persons.length > 0 && (
                  <>
                    {values.section2.persons.map((person, index) => (
                      <Card body className="mb-3" key={index}>
                        <h5>Person {index + 1}</h5>

                        <CommonFastField
                          label="Name"
                          accessor={`section2.persons.${index}.name`}
                          showLabel
                          type="text"
                          overridePlaceholder={true}
                        />

                        <GoogleAddressField
                          prefix={`section2.persons.${index}`}
                          accessor="address"
                        />

                        <CommonFastField
                          label="Phone"
                          accessor={`section2.persons.${index}.phone`}
                          showLabel
                          type="text"
                          overridePlaceholder={true}
                        />

                        <CommonFastField
                          label="Email"
                          accessor={`section2.persons.${index}.email`}
                          showLabel
                          type="text"
                          overridePlaceholder={true}
                        />

                        <CommonFastField
                          label="Driver's Licence"
                          accessor={`section2.persons.${index}.driversLicence`}
                          showLabel
                          type="text"
                          overridePlaceholder={true}
                        />
                      </Card>
                    ))}

                    {values.section1.customerType.value !== 3 && (
                      <div className="my-3">
                        <Link
                          className="sw-link"
                          onClick={(e) => {
                            e.preventDefault();
                            arrayHelpers.push({
                              id: generateUuid(),
                              name: "",
                              address: {
                                fullAddress: "",
                                unitNumber: "",
                                streetNumber: "",
                                streetName: "",
                                city: "",
                                state: "",
                                postcode: "",
                              },
                              phone: "",
                              email: "",
                              driversLicence: "",
                            });
                          }}
                          to=""
                        >
                          <i className="fas fa-plus mr-1" /> Add Person
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          />
          <SectionNavigation
            sectionId="section2"
            idsRegistered={idsRegistered}
            setToggle={setToggle}
            setToggleAll={setToggleAll}
          />
        </Col>
      </Row>
    </CollapsibleItem>
  );
};

function valuesAreEqual(prevProps, nextProps) {
  return (
    prevProps.values.section1.customerType ===
      nextProps.values.section1.customerType &&
    shallowequal(prevProps.values.section2, nextProps.values.section2) &&
    prevProps.collapseState.section2 === nextProps.collapseState.section2
  );
}

export default React.memo(Details, valuesAreEqual);

import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import SolvableDataTable from "./../../../components/common/solvable-datatable/solvable-datatable";
import columns from "./columns";
import ViewUsersFilterSection from "./filter-section";
import { Link } from "react-router-dom";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import LinksComponent from "./../admin-view/links-component";
import ButtonFx from "./../../../components/common/button-fx";
import withAdminHoc from "./../with-admin-hoc/with-admin-hoc";
import ConfirmationDialog from "./../../../components/common/confirmation-dialog";

const ManageUsers = ({
  isLoading,
  data,
  settings,
  loadUsers,
  filterUsers,
  usersCleanUp,
  keepDataTableSettings,
  deleteUser,
  isDeleting,
  history,
  memberType,
  isFetching,
}) => {
  const pageTitle = "Manage Users";
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const closeDeleteDialog = () => {
    toggleDeleteDialog(false);
    setUserToDelete(null);
  };

  useEffect(() => {
    if (!isFetching && memberType === 3) {
      loadUsers();
    }
    return () => usersCleanUp();
  }, [memberType]);

  const onSettingsChanged = (settings) => {
    keepDataTableSettings(settings);
  };

  const actionButtonHandler = ({ data, type }) => {
    switch (type) {
      case "button1":
        history.push(`/admin/update-user/${data.id}`);
        break;
      case "button2":
        setUserToDelete({
          id: data.id,
          name: `${data.firstName} ${data.lastName}`,
        });
        toggleDeleteDialog(true);
        break;
    }
  };

  let component = (
    <div className="mb-5">
      {!isFetching && (
        <EnhancedContentSection
          isBlocking={isLoading || isDeleting}
          showBackground={false}
          isFullWidth={true}
        >
          <div className="mb-3 mt-2">
            <LinksComponent pageTitle={pageTitle} />
          </div>

          <div className="mb-3">
            <Link to="/admin/create-user/" className="cta-link">
              <ButtonFx className="sw-btn-primary mr-2 mt-2">
                <i className="fas fa-user-plus mr-2" />
                Create a User
              </ButtonFx>
            </Link>
          </div>

          <div className="mb-4">
            <ViewUsersFilterSection filterUsers={filterUsers} />
          </div>

          {data.length > 0 && (
            <div className="content responsive-table">
              <Card body>
                <SolvableDataTable
                  columns={columns}
                  data={data}
                  actionButtons={{
                    type: "customButton",
                    containerWidth: 100,
                    custom: [
                      {
                        target: "button1",
                        icon: "fas fas fa-pencil-alt",
                        tooltip: "Edit User",
                      },
                      {
                        target: "button2",
                        icon: "fas fas fa-trash",
                        tooltip: "Delete User",
                      },
                    ],
                  }}
                  onActionButtonClick={actionButtonHandler}
                  defaultPageSize={10}
                  stateSettings={settings}
                  keepSettings={true}
                  onSettingsChanged={onSettingsChanged}
                />
              </Card>
            </div>
          )}

          {data.length === 0 && (
            <div className="content view-members">
              <Card body>
                <div className="d-flex p-3 justify-content-center empty">
                  <Row>
                    <Col>
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <i className="fas fa-user icon-style" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mt-2 d-flex justify-content-center text">
                            No users.
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
        </EnhancedContentSection>
      )}

      {/* Delete User Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        closeFunc={closeDeleteDialog}
        confirmFunc={() => {
          deleteUser(userToDelete.id);
          closeDeleteDialog();
        }}
        modalTitle="Delete User"
        modalMessage={`Are you sure you want to delete ${
          userToDelete && userToDelete.name
        }? This cannot be undone.`}
        btnConfirmText="Yes"
        btnCancelText="Cancel"
      />
      {/* Delete User Confirmation Dialog */}
    </div>
  );

  return withAdminHoc(component, memberType);
};

export default ManageUsers;

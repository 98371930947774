import generateUuid from "core/utilities/guid-generator";

const DetailsInitialValues = {
  persons: [
    {
      id: generateUuid(),
      name: "",
      address: {
        fullAddress: "",
        unitNumber: "",
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        postcode: "",
      },
      phone: "",
      email: "",
      driversLicence: "",
    },
  ],
};

export default DetailsInitialValues;

import React from "react";

const View = ({ image }) => {
  return (
    <a img src={`${image.url}`}>
      <div
        className="home-citiwest"
        style={{
          backgroundImage: "url('/images/traffic/" + image.name + "')",
          cursor: "pointer",
        }}
      ></div>
    </a>
  );
};

export default View;

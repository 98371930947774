import HttpClient from "./../../../core/http/httpClient";

const QuoteService = () => {
  let httpClient = HttpClient();

  const getQuote = (payload) => {
    let uri = "/get-a-quote";
    return httpClient.post(uri, {
      ...payload,
      services: payload.services.map((x) => x.value),
      source: payload.source.map((x) => x.value),
    });
  };

  return {
    getQuote,
  };
};

export default QuoteService;

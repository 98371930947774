import React, { useEffect } from "react";
import CollapsibleItem from "components/common/collapsible-panel/collapsible-item";
import CommonFastField from "components/common/common-fast-field";
import SectionNavigation from "components/common/collapsible-panel/section-navigation";
import Select from "components/common/select";
import { Col, Row, Label } from "reactstrap";
import customerTypes from "constants/customer-types";
import GoogleAddressField from "components/common/google-address-field";
import shallowequal from "shallowequal";
import FileUploader from "components/common/file-uploader/file-uploader";
import CustomErrorMessage from "components/common/error-message";
import MaskedInput from "components/common/masked-input";

const CustomerInformation = ({
  handleInit,
  collapseState,
  handleToggle,
  idsRegistered,
  setToggle,
  setToggleAll,
  values,
  setFieldValue,
  errors,
  validateForm,
}) => {
  useEffect(() => {
    if (values && values.section1 && values.section1.customerType) {
      setFieldValue(
        "section1.customerTypeValue",
        values.section1.customerType.value
      );
    }
  }, [values.section1 && values.section1.customerType]);

  return (
    <CollapsibleItem
      id="section1"
      key="1"
      title="Customer Information"
      init={handleInit}
      collapseState={collapseState}
      toggle={handleToggle}
    >
      <Row>
        <Col xs="12" lg="9">
          <CommonFastField
            label="Name of person completing this application"
            accessor="section1.personCompletingTheApplication"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="Position in company/business"
            accessor="section1.position"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <Select
            label="The Customer Is"
            accessor="section1.customerType"
            showLabel
            options={customerTypes}
            value={values.section1["customerType"]}
            closeMenuOnSelect={true}
          />

          <CommonFastField
            label="Full name of the Customer"
            accessor="section1.fullNameOfCustomer"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="Trading or Business Name"
            accessor="section1.businessName"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="ACN"
            accessor="section1.acn"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <MaskedInput
            label="Australian Business Number (ABN)"
            accessor="section1.abn"
            mask="99 999 999 999"
            showLabel
            overridePlaceholder
          />

          <GoogleAddressField
            prefix="section1"
            accessor="businessAddress"
            label="Business"
          />

          <div className="check-box">
            <CommonFastField
              label="Postal Address is the same as the Business Address"
              accessor="section1.sameAddresses"
              showLabel
              type="checkbox"
            />
          </div>

          {!values.section1.sameAddresses && (
            <GoogleAddressField
              prefix="section1"
              accessor="postalAddress"
              label="Postal"
            />
          )}

          <CommonFastField
            label="Phone"
            accessor="section1.phone"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="Fax"
            accessor="section1.fax"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="Email"
            accessor="section1.email"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="How long has Customer been trading?"
            accessor="section1.customerTradingLength"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          {values.section1.customerTypeValue === 4 && (
            <>
              <Label className="font-weight-bold">Trust Deed</Label>

              <FileUploader
                initialFiles={[]}
                maxFiles={1}
                maxSize={10485760}
                text="Click me or drag a file ato upload."
                dropText="Drop it like it's hot!"
                mode="Pdf"
                onChange={(files) => {
                  setFieldValue(
                    `section1.trustDeed`,
                    files.map(({ name, data, size }) => {
                      return {
                        name,
                        data,
                        size: Math.round(size / 1024),
                      };
                    })
                  );

                  validateForm();
                }}
              />

              <CustomErrorMessage
                touched={true}
                error={errors && errors.section1 && errors.section1.trustDeed}
              />
            </>
          )}

          <SectionNavigation
            sectionId="section1"
            idsRegistered={idsRegistered}
            setToggle={setToggle}
            setToggleAll={setToggleAll}
          />
        </Col>
      </Row>
    </CollapsibleItem>
  );
};

function valuesAreEqual(prevProps, nextProps) {
  return (
    shallowequal(prevProps.values.section1, nextProps.values.section1) &&
    shallowequal(prevProps.errors.section1, nextProps.errors.section1) &&
    prevProps.collapseState.section1 === nextProps.collapseState.section1
  );
}

export default React.memo(CustomerInformation, valuesAreEqual);

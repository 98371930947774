import ActionTypes from "./action-types";
import Service from "./../services/service";
import {
  addErrorNotification,
  addNotification,
  updateUserProfile,
} from "../../../redux/system/system-action-creators";

let service = Service();

export const updateProfile = (payload) => (dispatch) => {
  dispatch(updateProfileRequest());
  service
    .updateProfile(payload)
    .then(() => {
      dispatch(updateProfileSuccess());
      dispatch(updateUserProfile(payload));
      dispatch(addNotification("Your profile has been successfully updated."));
    })
    .catch((error) => {
      dispatch(updateProfileFailure());
      dispatch(addErrorNotification(error, "Unable to update your profile."));
    });
};

const updateProfileRequest = () => ({
  type: ActionTypes.UPDATE_PROFILE_REQUEST,
});

const updateProfileSuccess = () => ({
  type: ActionTypes.UPDATE_PROFILE_SUCCESS,
});

const updateProfileFailure = () => ({
  type: ActionTypes.UPDATE_PROFILE_FAILURE,
});

import React, { useEffect } from "react";
import { Form } from "formik";
import CollapsiblePanel from "components/common/collapsible-panel/collapsible-panel";
import FormikSubmitButton from "components/common/formik-submit-button";
import ExpandOnErrorComponent from "components/common/expand-on-error";
import EnhancedContentSection from "components/common/enhanced-content-section/index";

import CustomerInformation from "./collapsible-items/customer-information";
import Details from "./collapsible-items/details";
import AccountsPayableContact from "./collapsible-items/accounts-payable-contact";
import TermsAndConditions from "./collapsible-items/terms-conditions";
import PageTagging from "components/common/page-tagging/page-tagging";

const ApplicationForCredit = ({
  isSubmittingForm,
  isSubmitting,
  errors,
  setFieldValue,
  handleSubmit,
  values,
  cleanUp,
  setFieldError,
  validateForm,
}) => {
  useEffect(() => {
    return () => cleanUp();
  }, []);
  return (
    <>
      <EnhancedContentSection
        pageTitle="Application for Credit"
        isBlocking={isSubmittingForm}
        showBackground={true}
      >
        <PageTagging title="Application for Credit - Mr Traffic Hire" />

        <Form className="flex-fill mt-4" onSubmit={handleSubmit}>
          <CollapsiblePanel sectionOpen="section1">
            {({
              handleInit,
              collapseState,
              idsRegistered,
              handleToggle,
              setToggle,
              setToggleAll,
              setBatchToggleByState,
            }) => {
              return (
                <>
                  <ExpandOnErrorComponent
                    isSubmitting={isSubmitting}
                    errors={errors}
                    setBatchToggleByState={setBatchToggleByState}
                  />

                  {/* Customer Information */}
                  <div className="mb-3">
                    <CustomerInformation
                      handleInit={handleInit}
                      collapseState={collapseState}
                      idsRegistered={idsRegistered}
                      setToggle={setToggle}
                      handleToggle={handleToggle}
                      setToggleAll={setToggleAll}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      validateForm={validateForm}
                    />
                  </div>
                  {/* Customer Information */}

                  {/* Details */}
                  <div className="mb-3">
                    <Details
                      handleInit={handleInit}
                      collapseState={collapseState}
                      idsRegistered={idsRegistered}
                      setToggle={setToggle}
                      handleToggle={handleToggle}
                      setToggleAll={setToggleAll}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {/* Details */}

                  {/* Accounts Payable Contact */}
                  <div className="mb-3">
                    <AccountsPayableContact
                      handleInit={handleInit}
                      collapseState={collapseState}
                      idsRegistered={idsRegistered}
                      setToggle={setToggle}
                      handleToggle={handleToggle}
                      setToggleAll={setToggleAll}
                      values={values}
                    />
                  </div>
                  {/* Accounts Payable Contact */}

                  {/* Terms and Conditions */}
                  <div className="mb-3">
                    <TermsAndConditions
                      handleInit={handleInit}
                      collapseState={collapseState}
                      idsRegistered={idsRegistered}
                      setToggle={setToggle}
                      handleToggle={handleToggle}
                      setToggleAll={setToggleAll}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {/* Terms and Conditions */}

                  <div>
                    <FormikSubmitButton
                      label="Submit"
                      className="react-button cta-dark-blue-white-button"
                      isLoading={isSubmittingForm}
                      scrollToFirstError={true}
                      isCollapsibleForm={true}
                    />
                  </div>
                </>
              );
            }}
          </CollapsiblePanel>
        </Form>
      </EnhancedContentSection>
    </>
  );
};

export default ApplicationForCredit;

const initialValues = {
  name: "",
  email: "",
  phone: "",
  services: [],
  source: [],
  message: "",
};

export default initialValues;

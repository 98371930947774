const ActionTypes = {
  MANAGE_USERS_REQUEST: "MANAGE_USERS_REQUEST",
  MANAGE_USERS_SUCCESS: "MANAGE_USERS_SUCCESS",
  MANAGE_USERS_FAILURE: "MANAGE_USERS_FAILURE",

  MANAGE_USERS_DELETE_REQUEST: "MANAGE_USERS_DELETE_REQUEST",
  MANAGE_USERS_DELETE_SUCCESS: "MANAGE_USERS_DELETE_SUCCESS",
  MANAGE_USERS_DELETE_FAILURE: "MANAGE_USERS_DELETE_FAILURE",

  MANAGE_USERS_CLEAN_UP: "MANAGE_USERS_CLEAN_UP",
  MANAGE_USERS_DATATABLE_KEEP_SETTINGS: "MANAGE_USERS_DATATABLE_KEEP_SETTINGS",
};

export default ActionTypes;

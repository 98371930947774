import React from "react";
import LinksComponent from "./links-component";
import withAdminHoc from "./../with-admin-hoc/with-admin-hoc";
import PageTagging from "components/common/page-tagging/page-tagging";

const AdminView = ({ memberType, isFetching }) => {
  let component = (
    <div>
      {!isFetching && (
        <>
          <div className="mt-5">
            <LinksComponent />
          </div>
          <PageTagging title="" description="" />
        </>
      )}
    </div>
  );

  return withAdminHoc(component, memberType);
};

export default AdminView;

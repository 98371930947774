const CustomerInformationValues = {
  personCompletingTheApplication: "",
  position: "",
  customerType: "",
  customerTypeValue: "",
  fullNameOfCustomer: "",
  businessName: "",
  acn: "",
  abn: "",
  phone: "",
  fax: "",
  email: "",
  customerTradingLength: "",
  trustDeed: [],
  sameAddresses: true,

  businessAddress: {
    fullAddress: "",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
  },

  postalAddress: {
    fullAddress: "",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
  },
};

export default CustomerInformationValues;

import HttpClient from "./../../../../core/http/httpClient";
import moment from "moment";
import { toIsoDate } from "./../../../../core/utilities/date-service";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/v1/users";

  const loadDefaultUsers = () =>
    httpClient.post(`${uri}/filter`, {
      userTypes: [1, 2, 3],
      startDate: toIsoDate(moment().add("years", -1)),
      endDate: toIsoDate(moment()),
    });

  const filterUsers = (payload) => httpClient.post(`${uri}/filter`, payload);

  const deleteUser = (id) => httpClient.patch(`${uri}/${id}/archive`);

  return {
    loadDefaultUsers,
    filterUsers,
    deleteUser,
  };
};

export default Service;

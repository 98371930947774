import userTypes from "./../../../../../../constants/user-types";
import HttpClient from "./../../../../../../core/http/httpClient";

const CreateUpdateUserService = () => {
  let httpClient = HttpClient();
  let baseAccountUri = "/v1/core/accounts";
  let baseUseUri = "/v1/users";

  const createUser = (model) => {
    let uri = `${baseAccountUri}/admin`;

    let payload = {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      mobile: model.mobile,
      password: model.password,
      confirmPassword: model.confirmPassword,
      memberType: model.memberType.value,
    };
    return httpClient.post(uri, payload).then(({ data }) => data);
  };

  const updateUser = (model) => {
    let payload = {
      id: model.id,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      mobile: model.mobile,
      memberType: model.memberType.value,
    };
    return httpClient.put(`${baseUseUri}/admin/update-user`, payload);
  };

  const getUserDetails = (id) => {
    return httpClient.get(`${baseUseUri}/${id}`).then(({ data }) => {
      return {
        ...data,
        memberType: userTypes.find(
          (userType) => userType.value === data.userType
        ),
      };
    });
  };

  return {
    createUser,
    getUserDetails,
    updateUser,
  };
};

export default CreateUpdateUserService;

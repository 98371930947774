import React from "react";
import { FormGroup, Col, Row, Label } from "reactstrap";
import CommonField from "./../common-field";
import australian_states from "./australian-states";
import Select from "./../select";
import { Field } from "formik";

const ManualAddressEntry = ({ accessor, label, prefix }) => {
  const getAccessor = () => {
    if (prefix) {
      return `${prefix}.${accessor}.`;
    }

    return accessor ? `${accessor}.` : "";
  };

  let baseAccessor = getAccessor();

  return (
    <div>
      {label && (
        <Label className="font-weight-bold mb-3" style={{ fontWeight: "bold" }}>
          {label} Address
        </Label>
      )}
      <Row form>
        <Col xs="12" lg="3">
          <FormGroup>
            <CommonField
              label={`Unit Number`}
              accessor={`${baseAccessor}unitNumber`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="3">
          <FormGroup>
            <CommonField
              label={`Street Number`}
              accessor={`${baseAccessor}streetNumber`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="6">
          <FormGroup>
            <CommonField
              label={`Street Name`}
              accessor={`${baseAccessor}streetName`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col xs="12" lg="5">
          <FormGroup>
            <CommonField
              label={`City`}
              accessor={`${baseAccessor}city`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="4">
          <FormGroup>
            <Field>
              {({ form, field }) => (
                <div>
                  <Select
                    label={`State`}
                    accessor={`${baseAccessor}state`}
                    showLabel
                    options={australian_states}
                    value={field.value}
                    closeMenuOnSelect={true}
                  />
                </div>
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col xs="12" lg="3">
          <FormGroup>
            <CommonField
              label={`Post Code`}
              accessor={`${baseAccessor}postcode`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ManualAddressEntry;

import { useEffect } from "react";
import states from "./../../../components/common/google-address-field/australian-states";
import { isNil } from "lodash";

const useIinitialiseProfilePage = (profile, setValues) => {
  useEffect(() => {
    if (profile.memberType != "" && !isNil(profile.memberType)) {
      if (profile.memberType === 2) {
        setValues({
          memberType: profile.memberType,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          mobile: profile.mobile,
          companyName: profile.companyName,
          fullAddress: profile.fullAddress,
          unitNumber: profile.unitNumber,
          streetNumber: profile.streetNumber,
          streetName: profile.streetName,
          city: profile.city,
          state: states.find((state) => state.value === profile.state),
          postcode: profile.postcode,
        });
      } else {
        setValues({
          memberType: profile.memberType,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          mobile: profile.mobile,
        });
      }
    }
  }, [profile.memberType]);
};

export default useIinitialiseProfilePage;

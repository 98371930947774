const initialValues = {
  id: "",
  isEdit: false,
  firstName: "",
  lastName: "",
  memberType: "",
  email: "",
  mobile: "",
  password: "",
  confirmPassword: "",
};

export default initialValues;

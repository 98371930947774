const registrationInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
  confirmPassword: "",
  acceptedTheTerms: false,
};

export default registrationInitialValues;

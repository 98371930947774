const sendBy = [
  {
    value: 0,
    label: "Email",
  },
  {
    value: 1,
    label: "Fax",
  },
  {
    value: 2,
    label: "Post",
  },
];

export default sendBy;

import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const TruckMountedAttenuators = () => {
  let pageTitle = "Truck Mounted Attenuators";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Truck Mounted Attenuator Hire Melbourne | TMA Hire | TMA Rental"
          description="Melbourne's leading Truck Mounted Attenuator Hire. Let us help with your worksite protection utilising our state of the art Truck Mounted Attenuators (TMA’s)."
        />
        <div className="mt-3 services-default">
          <img
            src="/images/tma.jpg"
            alt="Truck Mounted Attenuators TMA trucks"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>TMA&rsquo;s Save Lives</h5>
            <p>
              Let us help with your worksite protection utilising our state of
              the art Truck Mounted Attenuators (TMA&rsquo;s). We recognise that
              TMA&rsquo;s are a key part of the hierarchy of controls for
              worksite protection and have invested in the latest equipment.
            </p>
            <p>
              Our trucks meet all requirements of the National Guidelines for
              Truck Mounted Attenuators TMA-NWP-01 and NHVR Vehicle Standards
              Guide (VSG-12)
            </p>

            <p className="mt-3">
              <h5>Key Features</h5>

              <ul className="mt-3">
                <li>Fit for purpose trucks</li>
                <li>Front under-run protection</li>
                <li>TL3 Rated TMA</li>
                <li>C Class LED Arrowboard</li>
                <li>VMS Board</li>
                <li>High intensity LED lights</li>
                <li>Rear cameras</li>
                <li>Impact Braking system</li>
                <li>Air Horn</li>
                <li>4 Point safety harness</li>
                <li>Driver seat protection</li>
                <li>Heavy duty head board for driver protection</li>
                <li>UHF radio</li>
                <li>GPS tracking</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default TruckMountedAttenuators;

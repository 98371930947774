import React from "react";
import EnhancedContentSection from "../../../components/common/enhanced-content-section";
import TitleHeader from "../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const Portaboom = () => {
  let pageTitle = "Portaboom";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Mr Traffic Hire | Portaboom Hire Melbourne"
          description="Melbourne's leading Portaboom Hire"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/portaboom.jpg"
            alt="Portaboom Hire"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>Improve safety with PORTABOOM®</h5>
            <p>
              PORTABOOM® is a patented, portable boom gate specifically designed
              for all temporary traffic and access control scenarios. Remote
              control operated, PORTABOOM® allows Traffic Controllers to stand
              in a safe zone, removing them from the direct line of traffic.{" "}
            </p>

            <p className="mt-3">
              <h5>Meets all requirements of AS4191</h5>

              <ul className="mt-3">
                <li>Easy to use</li>
                <li>Quick set up</li>
                <li>Battery operated</li>
                <li>Training provided</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default Portaboom;

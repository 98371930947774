import React from "react";
import { useExpandOnError } from "./use-expand-on-error";

const ExpandOnErrorComponent = ({
  errors,
  isSubmitting,
  setBatchToggleByState,
}) => {
  useExpandOnError({
    isSubmitting,
    errors,
    setBatchToggleByState,
  });

  return <></>;
};

export default ExpandOnErrorComponent;

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import { withFormik } from "formik";
import initialValues from "./formik/formik/initial-values";
import validationSchema from "./formik/formik/validation-schema";

const mapStateToProps = (state) => {
  return {
    contactUs: state.quote,
    profile: state.system.userProfile,
  };
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit: (values, { props }) => {
    props.getQuote(values);
  },
  displayName: "getQuoteForm",
})(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(EnhancedForm)
);

import * as Yup from "yup";

import CustomerInformationValidationSchema from "./sub-schemas/customer-information-validation-schema";
import DetailsValidationSchema from "./sub-schemas/details-validation-schema";
import AccountsPayableContactValidationSchema from "./sub-schemas/accounts-payable-contact-validation-schema";
import TermsAndConditionsValidationSchema from "./sub-schemas/terms-conditions-validation-schema";

const enrolmentValidationSchema = Yup.object().shape({
  section1: CustomerInformationValidationSchema,
  section2: DetailsValidationSchema,
  section3: AccountsPayableContactValidationSchema,
  section4: TermsAndConditionsValidationSchema,
});

export default enrolmentValidationSchema;

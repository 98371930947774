import React from "react";
import { Row, Col, Card } from "reactstrap";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import items from "./items";
import "./details.scss";
import PageTagging from "components/common/page-tagging/page-tagging";
import { Link } from "react-router-dom";

const ServicesLandingPage = ({ showPageTitle }) => {
  let showTitle =
    showPageTitle === undefined || showPageTitle === true ? true : false;

  return (
    <EnhancedContentSection
      isBlocking={false}
      showPageTitle={showTitle}
      bgClass="sw-bg-std-grey"
      pageTitle="Services"
    >
      <PageTagging
        title="Services | Traffic Equipment Hire | VMS, Arrow Boards | TMA Hire"
        description="We provide a wide range of specialised traffic equipment hire products across Victoria. VMS Boards, Arrow Boards, Truck Mounted Attenuators and more"
      />
      <p className="mt-3 text-justify">
        We provide a wide range of traffic management services across Victoria,
        Australia. This includes Variable Message Signs (VMS), Arrow Boards,
        Variable Speed Limit Signs (VSLS), Portable Traffic Lights, Truck
        Mounted Attenuator Trucks (TMA’s), Traffic Utes, Highway Trucks. We also
        provide VMS repairs and maintenance services.
      </p>
      <h5>Check out our services in more detail.</h5>

      <Row className="details mt-3" form>
        {items.map((x) => {
          return (
            <Col md="4" xs="12" className="mb-3" key={x.id}>
              <Card className="shadow main-wrapper">
                <div
                  className="card-img d-flex flex-row justify-content-center align-items-center"
                  style={{
                    backgroundImage: x.image,
                  }}
                ></div>
                <div className="card-text d-flex flex-row justify-content-center align-items-center p-2">
                  <Link to={x.link}>
                    <h3>{x.title}</h3>
                  </Link>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </EnhancedContentSection>
  );
};

export default ServicesLandingPage;

import HttpClient from "./../../../core/http/httpClient";

const RegistrationService = () => {
  let httpClient = HttpClient();

  const register = (model) => {
    let uri = "/v1/core/accounts";
    return httpClient.post(uri, { ...model, memberType: "1" });
  };

  return {
    register,
  };
};

export default RegistrationService;

import React, { useEffect } from "react";
import CommonFastField from "components/common/common-fast-field";
import { Form } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import ContentService from "services/content-service";
import FormikSubmitButton from "components/common/formik-submit-button";
import EnhancedContentSection from "components/common/enhanced-content-section";
import useContactUsHandler from "./hooks/use-effect-handler";
import { useFillContactData } from "components/common/common-hooks/use-fill-profile-data";
import PageTagging from "components/common/page-tagging/page-tagging";

const ContactUs = ({
  cleanUp,
  contactUs,
  handleSubmit,
  setFieldValue,
  profile,
  match,
  handleReset,
  resetForm,
}) => {
  const { isLoading, isSuccessful } = contactUs;

  useFillContactData(profile, setFieldValue);

  useContactUsHandler(
    match.params.courseId,
    isSuccessful,
    handleReset,
    setFieldValue,
    cleanUp
  );

  useEffect(() => {
    if (isSuccessful) {
      resetForm();
    }
  }, [isSuccessful]);

  return (
    <>
      <EnhancedContentSection
        isBlocking={isLoading}
        showPageTitle={true}
        pageTitle={ContentService().getContentPage("get-in-touch").title}
      >
        <PageTagging title="Mr Traffic Hire | Contact Us" />
        {ContentService().getContentPage("get-in-touch").content}

        <Form className="flex-fill mt-3" onSubmit={handleSubmit}>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label="Name"
                accessor="name"
                showLabel
                type="text"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label="Email Address"
                accessor="email"
                showLabel
                type="text"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label={`Phone Number`}
                accessor="phone"
                showLabel
                overridePlaceholder={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="9">
              <CommonFastField
                label="Message"
                accessor="message"
                showLabel
                type="textarea"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>

          <FormGroup>
            <FormikSubmitButton
              label="Send"
              isLoading={isLoading}
              className="sw-btn-primary"
              scrollToFirstError={true}
            />
          </FormGroup>
        </Form>

        <h2 className="mt-4">Our Locations</h2>

        <Row className="mt-4">
          <Col>
            <h6>10 Everaise Court, Laverton North, VIC 3206</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6301.724412075147!2d144.7841767!3d-37.8401116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad68a074c7add63%3A0xc619d3bb57ae967c!2s10%20Everaise%20Ct%2C%20Laverton%20North%20VIC%203026!5e0!3m2!1sen!2sau!4v1725849754901!5m2!1sen!2sau"
              height="450"
              frameborder="0"
              style={{ border: "0", width: "100%" }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Col>

          <Col>
            <h6>1/13 Aster Ave, Carrum Downs, VIC 3201</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d273.52138778175146!2d145.16609073722327!3d-38.10407493288616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60cec95e19193%3A0x73e81540845d09e2!2sAccurate%20Cabinets%2C%201%2F13%20Aster%20Ave%2C%20Carrum%20Downs%20VIC%203201%2C%20Australia!5e0!3m2!1sen!2shk!4v1606316535433!5m2!1sen!2shk"
              height="450"
              frameborder="0"
              style={{ border: "0", width: "100%" }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Col>
        </Row>
      </EnhancedContentSection>
    </>
  );
};

export default ContactUs;

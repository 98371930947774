import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import { withFormik } from "formik";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import * as ActionCreators from "./redux/action-creators";
import { addNotification } from "./../../redux/system/system-action-creators";

const mapStateToProps = ({ applicationForCredit }) => applicationForCredit;

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  validateOnBlur: true,
  validateOnChange: false,
  handleSubmit: (values, { props, resetForm, setFieldError }) => {
    const { applyForCredit, addNotification } = props;

    if (values.section3.sendBy === 1 && !values.section1.fax) {
      addNotification("Please add a fax number.", "error");
      return false;
    }

    if (values.section4.signatures.some((x) => !x.specimen)) {
      addNotification("Please sign the document.", "error");
      return false;
    }

    if (
      values.section1.customerType.value === 0 &&
      values.section4.guarantors.some((x) => !x.specimen)
    ) {
      addNotification(
        "Please sign the guarantee and indemnity section.",
        "error"
      );
      return false;
    }

    applyForCredit(values, resetForm);
  },
  displayName: "applicationForCreditForm",
});

const enhancedForm = formikEnhancer(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators, addNotification })(enhancedForm)
);

const mrTrafficServices = [
  "Arrow Boards",
  "VMS Boards",
  "Portable Traffic Lights",
  "Travel Times - Data Collection",
  "Variable Speed Limit Signs",
  "Truck Mounted Attenuators",
  "Highway Trucks",
  "Traffic Utes",
  "VMS Utes",
  "Portaboom",
  "Other",
];

const getServicesAsArrayOfObjects = () => {
  return mrTrafficServices.map((x) => {
    return {
      value: x,
      label: x,
    };
  });
};

export { mrTrafficServices as default, getServicesAsArrayOfObjects };

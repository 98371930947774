import React from "react";
import ImageHeader from "./../../components/common/image-header";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import ButtonFx from "./../../components/common/button-fx";
import Partner from "./components/partner";
import Citiwest from "./component/citiwest";
import { useHistory } from "react-router-dom";
import PageTagging from "components/common/page-tagging/page-tagging";
import ContentSection from "components/common/content-section";

const Home = () => {
  let history = useHistory();
  return (
    <>
      <ImageHeader imgSrc={`/images/flinders-street-station.jpg`} />
      {/* <ContentSection bgClass="sw-bg-std-grey">
        <div className="row">
          <div className="col-md-7 col-12"></div>
            <h2>Looking for Equipment Rentals?</h2>
            <strong>
              Citiwest Rentals - Providing equipment hire to all of western
              Melbourne
            </strong>
            <p>
              Citiwest Rentals is the latest addition to the Mr Traffic Hire
              family. We are a leading provider of rental equipment in the west.
              Get everything you need for your home renovation and business
              needs.
            </p>
            <strong>
              Get the right equipment and advice for the right price!
            </strong>
            <div className="mb-4 mt-4">
              <a
                href="https://www.citiwestrentals.com.au/"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-blue-mini-button btn btn-secondary"
              >
                Check out Citiwest
              </a>
            </div>
            <a
              href="https://www.citiwestrentals.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/citiwest.png"
                alt="Citiwest Rentals"
                className="gp-image shadow"
              />
            </a>
          </div>
          <div className="col-md-5 col-12">
            <img
              src="/images/citiwest-machine.jpg"
              alt="Citiwest Rentals"
              className="gp-image shadow"
            />
          </div>
        </div>
      </ContentSection> */}
      <Citiwest />
      <EnhancedContentSection isBlocking={false} showPageTitle={true}>
        <PageTagging
          title="Mr Traffic Hire | Specialised Traffic Equipment Hire Melbourne"
          description="Mr Traffic Hire provide a wide range of specialised traffic equipment hire products across Victoria. VMS Boards, Arrow Boards, Truck Mounted Attenuators and more"
        />
        <h2>About Us</h2>

        <div className="mt-4 text-justify">
          <p>
            Mr Traffic Hire offers specialised traffic equipment hire on any
            scale, such as large infrastructure road works and projects to
            single Variable Messaging Signs (VMS) for advertising. We have a
            full range of traffic safety equipment.{" "}
          </p>

          <p>
            With over 25 years combined experience in the Hire industry Mr
            Traffic Hire can offer our clients the most up to date solutions for
            managing your needs, combined with our vast industry knowledge,
            makes us confident that we can provide solutions to our clients.
          </p>

          <p>
            At Mr Traffic Hire we endeavour to work closely with all our clients
            to find a solution that works and with our expert knowledge and
            understanding of current legislation, in addition to providing the
            highest level of quality, service and value, Mr Traffic Hire is
            confident we can help you achieve your targets.
          </p>

          <p>
            Mr Traffic Hire is available 24 hours a day, 7 days a week and we
            will work within your timeframes and budget to make it happen.
          </p>

          <ButtonFx
            onClick={() => history.push("/about/")}
            className="mt-blue-mini-button"
          >
            Learn More
          </ButtonFx>
        </div>
      </EnhancedContentSection>

      <Partner />
    </>
  );
};

export default Home;

const Config = {
  appVersion: "1.0",
  appAuthStorageKey: "mrt-app-auth",
  defaultPageTitle: "Mr Traffic",
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: "bf4bbf8202934f64a8ccfcac8d419679",
  gtmId: process.env.REACT_APP_GTM_ID,
};

export default Config;

import React from "react";
//import { Link } from "react-router-dom";
import ContentSection from "./../components/common/content-section";
import { corePublicPages } from "./public-content-core";

export const publicPages = [
  ...corePublicPages,
  {
    pageName: "new-page",
    title: "New Page",
    description: "",
    content: (
      <>
        <h1>New Page</h1>
      </>
    ),
  },
  {
    pageName: "new-page-2",
    title: "New Page",
    description: "",
    noContentSection: true,
    content: (
      <>
        <ContentSection>
          <h1>Section 1</h1>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey ">
          <h1>Section 2</h1>
        </ContentSection>
        <ContentSection>
          <h1>Section 3</h1>
        </ContentSection>
      </>
    ),
  },
];

import { combineReducers } from "redux";
import SystemReducer from "./system/system-reducer";
import ContactUsReducer from "./../views/contact-us/redux/reducer";
import QuoteReducer from "./../views/quote/redux/reducer";
import UserLoginReducer from "./../views/login/redux/reducer";
import RegistrationReducer from "./../views/register/redux/reducer";
import ForgotPasswordReducer from "./../views/forgot-password/redux/reducer";
import ResetPasswordReducer from "./../views/reset-password/redux/reducer";
import UpdateProfileReducer from "./../views/update-profile/redux/reducer";
import ManageUsersReducer from "./../views/admin/manage-users/redux/reducer";
import CreateUpdateUserReducer from "./../views/admin/manage-users/components/create-update-user/redux/reducer";
import ApplicationForCreditReducer from "./../views/application-for-credit/redux/reducer";

const RootReducer = combineReducers({
  system: SystemReducer,
  userLogin: UserLoginReducer,
  register: RegistrationReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  contactUs: ContactUsReducer,
  updateProfile: UpdateProfileReducer,
  manageUsers: ManageUsersReducer,
  createUpdateUser: CreateUpdateUserReducer,
  quote: QuoteReducer,
  applicationForCredit: ApplicationForCreditReducer,
});

export default RootReducer;

/**
 * Navigation Plugin for Collapsible item
 * - Previous and Next button
 * for sample please see audits-page/audit-sections.jsx
 *
 * 03.12.2019 EH Change implementation when extracting navigation props (do it on the Fly!)
 */
import React from "react";
import { Row, Col } from "reactstrap";
import { SectionLink } from "react-scroll-section";
import ButtonFx from "./../button-fx";

/**
 * Determine navigation props on the fly!
 * @param {*} id
 * @param {*} sectionIds
 */
const getNavigationProps = (id, sectionIds) => {
  let indx = sectionIds.indexOf(id);
  if (indx === -1)
    return {
      previousId: null,
      nextId: null
    };

  let prevId = indx === 0 ? null : sectionIds[indx - 1];
  let nextId = indx >= sectionIds.length - 1 ? null : sectionIds[indx + 1];

  return {
    previousId: prevId,
    nextId
  };
};

const SectionNavigation = props => {
  const { idsRegistered, sectionId, setToggle, setToggleAll } = props;
  const navProps = getNavigationProps(sectionId, idsRegistered);
  const { previousId, nextId } = navProps;

  const prev = scrollFn => {
    setToggleAll(false);
    if (previousId) {
      setToggle(previousId, true);
      scrollFn();
    }
  };

  const next = scrollFn => {
    setToggleAll(false);
    if (nextId) {
      setToggle(nextId, true);
      setTimeout(() => scrollFn(), 500);
    }
  };

  return (
    <Row>
      <Col>
        {previousId && (
          <SectionLink section={previousId}>
            {({ onClick }) => (
              <span className="mr-2">
                <ButtonFx
                  type="button"
                  className="mr-2"
                  disabled={false}
                  className={
                    "acpan-link acpan-link-gray custom-button react-button"
                  }
                  onClick={() => prev(onClick)}
                >
                  <i className="fas fa-chevron-left"></i> Previous
                </ButtonFx>
              </span>
            )}
          </SectionLink>
        )}

        {nextId && (
          <SectionLink section={nextId} offset={-70}>
            {({ onClick }) => (
              <ButtonFx
                type="button"
                disabled={false}
                className={
                  "acpan-link acpan-link-blue custom-button react-button"
                }
                onClick={() => next(onClick)}
              >
                Next <i className="fas fa-chevron-right"></i>
              </ButtonFx>
            )}
          </SectionLink>
        )}
      </Col>
    </Row>
  );
};

export default SectionNavigation;

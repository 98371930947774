import ActionTypes from "./action-types";
import RegistrationService from "./../services/service";
import {
  addErrorNotification,
  setProfile,
} from "../../../redux/system/system-action-creators";
import Auth from "./../../../core/auth/auth";
import UserIdentity from "./../../../core/auth/userIdentity";
import ProfileService from "./../../../services/profile-service";

let service = RegistrationService();
let profileService = ProfileService();

export const register = (model, history) => (dispatch, getState) => {
  dispatch(registerRequest());
  service
    .register(model)
    .then(({ data }) => {
      Auth.removeToken();
      Auth.setToken(data.access_token, data.refresh_token, data.expires_in);

      const userIdent = UserIdentity();
      profileService
        .getProfile(userIdent.getUserId())
        .then(({ data }) => {
          dispatch(setProfile(data));
          dispatch(registerSuccess());

          history.push("/");
        })
        .catch((error) =>
          dispatch(
            addErrorNotification(error, "Unable to fetch the user profile.")
          )
        );
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to register."));
      dispatch(registerFailure(error));
    });
};

const registerRequest = () => ({
  type: ActionTypes.REGISTER_REQUEST,
});

const registerSuccess = () => ({
  type: ActionTypes.REGISTER_SUCCESS,
});

const registerFailure = (error) => ({
  type: ActionTypes.REGISTER_FAILURE,
  payload: error,
});

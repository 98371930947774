import React, { useEffect } from "react";
import CommonFastField from "components/common/common-fast-field";
import { Form } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import FormikSubmitButton from "components/common/formik-submit-button";
import Select from "components/common/select";
import EnhancedContentSection from "components/common/enhanced-content-section";
import useQuoteHandler from "./hooks/use-effect-handler";
import { useFillContactData } from "components/common/common-hooks/use-fill-profile-data";
import { getServicesAsArrayOfObjects } from "constants/services";
import { getSourceAsArrayOfObjects } from "constants/source";
import PageTagging from "components/common/page-tagging/page-tagging";

const GetQuote = ({
  cleanUp,
  contactUs,
  handleSubmit,
  setFieldValue,
  profile,
  match,
  handleReset,
  values,
  resetForm,
}) => {
  const { isLoading, isSuccessful } = contactUs;

  useFillContactData(profile, setFieldValue);

  useQuoteHandler(
    match.params.courseId,
    isSuccessful,
    handleReset,
    setFieldValue,
    cleanUp
  );

  useEffect(() => {
    if (isSuccessful) {
      resetForm();
    }
  }, [isSuccessful]);

  return (
    <>
      <EnhancedContentSection
        isBlocking={isLoading}
        showPageTitle={true}
        pageTitle="Get A Quote"
      >
        <PageTagging title="Mr Traffic Hire | Get a Quote" />
        <Form className="flex-fill mt-3" onSubmit={handleSubmit}>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label="Name"
                accessor="name"
                showLabel
                type="text"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label="Email Address"
                accessor="email"
                showLabel
                type="text"
                overridePlaceholder={false}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <CommonFastField
                label={`Phone Number`}
                accessor="phone"
                showLabel
                overridePlaceholder={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <Select
                label="Services"
                accessor="services"
                showLabel
                options={getServicesAsArrayOfObjects()}
                isMulti
                allowSelectAll
                value={values.services}
                closeMenuOnSelect={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <Select
                label="How did you hear about us?"
                accessor="source"
                showLabel
                options={getSourceAsArrayOfObjects()}
                isMulti
                allowSelectAll
                value={values.source}
                closeMenuOnSelect={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="9">
              <CommonFastField
                label="Message"
                accessor="message"
                showLabel
                type="textarea"
                overridePlaceholder={true}
                showRequiredIndicator={true}
              />
            </Col>
          </Row>

          <FormGroup>
            <FormikSubmitButton
              label="Submit"
              isLoading={isLoading}
              className="sw-btn-primary"
              scrollToFirstError={true}
            />
          </FormGroup>
        </Form>
        <div className="mt-5 text-center">
          <a
            href="https://creditorwatch.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/partners/creditorwatch.png"
              alt="Creditor Watch"
              className="gp-image shadow"
              style={{ maxWidth: "200px" }}
            />
          </a>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default GetQuote;

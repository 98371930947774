import * as Yup from "yup";

const registrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("Last name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Invalid email address"),
  mobile: Yup.string()
    .required("Mobile is required.")
    .matches(/^$|\s+/, "Mobile is required."),

  //Password fields validations
  password: Yup.string(),
  confirmPassword: Yup.string()
    .min(1)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  // confirmPassword: Yup.string()

  acceptedTheTerms: Yup.bool().oneOf(
    [true],
    "Please accept the terms and conditions."
  ),
});

export default registrationValidationSchema;

const getAddress = googleAddress => {
  if (!googleAddress || googleAddress === undefined)
    return {
      fullAddress: "",
      unitNumber: "",
      streetNumber: "",
      streetName: "",
      city: "",
      state: "",
      postcode: ""
    };

  let address = {
    fullAddress: "",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: ""
  };

  let promiseObject = new Promise(res => {
    getGeocodeAddress(googleAddress, function(addressComponents) {
      address.fullAddress = googleAddress.formatted_address;

      for (let addressComponent of addressComponents) {
        for (let i = 0; i < addressComponent.types.length; i++) {
          let type = addressComponent.types[0];

          switch (type) {
            case "subpremise":
              address.unitNumber = addressComponent.short_name;
              break;
            case "street_number":
              address.streetNumber = addressComponent.short_name;
              break;
            case "route":
              address.streetName = addressComponent.short_name;
              break;
            case "locality":
              address.city = addressComponent.short_name;
              break;
            case "administrative_area_level_1":
              address.state = addressComponent.short_name;
              break;
            case "postal_code":
              address.postcode = addressComponent.short_name;
              break;
            default:
              break;
          }
        }
      }

      res(address);
    });
  });

  return promiseObject;
};

const getGeocodeAddress = function(googleAddress, f) {
  const google = window.google;
  const geocoder = new google.maps.Geocoder();

  geocoder.geocode({ address: googleAddress }, function(results, status) {
    if (status === "OK") {
      if (results[0]) f(results[0].address_components);
      else f(googleAddress.address_components);
    } else f(googleAddress.address_components);
  });
};

export default getAddress;

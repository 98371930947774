const customerTypes = [
  {
    value: 0,
    label: "Pty Ltd Company",
  },
  {
    value: 1,
    label: "Public Company",
  },
  {
    value: 2,
    label: "Partnership",
  },
  {
    value: 3,
    label: "Sole Trader",
  },
  {
    value: 4,
    label: "Trust",
  },
];

export default customerTypes;

const ActionTypes = {
  CREATE_UPDATE_USER_REQUEST: "CREATE_UPDATE_USER_REQUEST",
  CREATE_UPDATE_USER_SUCCESS: "CREATE_UPDATE_USER_SUCCESS",
  CREATE_UPDATE_USER_FAILURE: "CREATE_UPDATE_USER_FAILURE",
  CREATE_UPDATE_USER_CLEAN_UP: "CREATE_UPDATE_USER_CLEAN_UP",

  CREATE_UPDATE_USER_GET_DETAILS_REQUEST:
    "CREATE_UPDATE_USER_GET_DETAILS_REQUEST",
  CREATE_UPDATE_USER_GET_DETAILS_SUCCESS:
    "CREATE_UPDATE_USER_GET_DETAILS_SUCCESS",
  CREATE_UPDATE_USER_GET_DETAILS_FAILURE:
    "CREATE_UPDATE_USER_GET_DETAILS_FAILURE",

  CREATE_UPDATE_USER_CLEAN_UP: "CREATE_UPDATE_USER_CLEAN_UP",
};

export default ActionTypes;

import HttpClient from "core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/apply-for-credit";

  const applyForCredit = (values) => {
    let payload = formatModel(values);
    return httpClient.post(uri, payload, {
      responseType: "blob",
    });
  };

  const formatModel = (values) => {
    let businessAddress = {
      ...values.section1.businessAddress,
      state: values.section1.businessAddress.state.value,
    };

    let payload = {
      section1: {
        ...values.section1,
        customerType: values.section1.customerType.value,
        businessAddress,
        postalAddress: values.section1.sameAddresses
          ? businessAddress
          : {
              ...values.section1.postalAddress,
              state: values.section1.postalAddress.state.value,
            },
        trustDeed:
          values.section1.customerTypeValue === 4
            ? JSON.stringify(values.section1.trustDeed[0])
            : "",
      },
      section2: {
        persons: values.section2.persons.map((x) => {
          return {
            ...x,
            address: {
              ...x.address,
              state: x.address.state.value,
            },
          };
        }),
      },
      section3: {
        ...values.section3,
        sendBy: values.section3.sendBy.value,
      },
      section4: {
        signatures: JSON.stringify(values.section4.signatures),
        guarantors: JSON.stringify(values.section4.guarantors),
      },
    };

    delete payload.section1.customerTypeValue;
    delete payload.section1.sameAddresses;

    return payload;
  };

  return {
    applyForCredit,
  };
};

export default Service;

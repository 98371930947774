import React, { Component } from "react";
import View from "./view";
import { citiwests as CitiwestItems } from "constants/citiwests";

class Citiwests extends Component {
  render() {
    return <View items={CitiwestItems} />;
  }
}

export default Citiwests;

import React, { useEffect } from "react";

const GoogleAddressSubmissionValidationHook = ({
  form,
  toggleManualEntry,
  accessor,
  prefix,
}) => {
  useEffect(() => {
    if (!form.isSubmitting && !form.isValid) {
      let requiredAddressFields = [
        "streetNumber",
        "streetName",
        "city",
        "state",
        "postcode",
      ];

      if (prefix) {
        let errEntries = Object.entries(form.errors);
        let toggled = false;

        if (errEntries.length > 0) {
          errEntries.forEach((err) => {
            let errSectionEntries = Object.keys(err[1]);

            if (errSectionEntries.find((x) => x === accessor)) {
              toggleManualEntry(true);
              toggled = true;
            }
          });
        }
      } else if (accessor) {
        let errEntries = Object.entries(form.errors);
        let toggled = false;

        if (errEntries.length > 0) {
          errEntries.forEach((err) => {
            let errSectionEntries = Object.keys(err[1]);

            errSectionEntries.forEach((er) => {
              if (requiredAddressFields.includes(er) && !toggled) {
                toggleManualEntry(true);
                toggled = true;
              }
            });
          });
        }
      } else {
        let toggled = false;
        let errorKeys = Object.keys(form.errors);

        errorKeys.forEach((err) => {
          if (requiredAddressFields.includes(err) && !toggled) {
            toggleManualEntry(true);
            toggled = true;
          }
        });
      }
    }
  }, [form.isSubmitting]);

  return <></>;
};

export default GoogleAddressSubmissionValidationHook;

import HttpClient from "./../../../core/http/httpClient";

const UpdateProfileService = () => {
  let httpClient = HttpClient();

  const updateProfile = (values) => {
    let uri = "/v1/core/accounts/update/own-profile/";
    let payload =
      values.memberType === 2
        ? {
            ...values,
            state: values.state.value,
            postCode: values.postcode,
          }
        : values;
    return httpClient.put(uri, payload);
  };

  return {
    updateProfile,
  };
};

export default UpdateProfileService;

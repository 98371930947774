import React from "react";
import { Form, withFormik } from "formik";
import { Row, Col } from "reactstrap";
import CommonField from "./../../components/common/common-field";
import MaskedInput from "./../../components/common/masked-input";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import validationSchema from "./formik/validation-schema";
import GoogleAddressField from "./../../components/common/google-address-field";
import useIinitialiseProfilePage from "./hooks/initialise";

const UpdateProfile = ({ isUpdating, profile, setValues, values }) => {
  let pageTitle = "Update Profile";

  useIinitialiseProfilePage(profile, setValues);

  return (
    <EnhancedContentSection pageTitle={pageTitle} isBlocking={false}>
      <div className="my-3">
        <Form className="flex-fill mt-4">
          <Row>
            <Col xs="12" lg="9">
              {profile.memberType === 2 && (
                <>
                  <CommonField
                    label="Company Name"
                    accessor="companyName"
                    showLabel
                    type="text"
                  />

                  <MaskedInput
                    label={`Australian Business Number (ABN)`}
                    accessor={`abn`}
                    mask="99 999 999 999"
                    showLabel
                  />

                  <div className="check-box">
                    <CommonField
                      label={`We are an Employment Service Provider (ESP)`}
                      accessor={``}
                      showLabel
                      type={`checkbox`}
                    />
                  </div>
                </>
              )}

              <Row form>
                <Col md={6}>
                  <CommonField
                    label="First Name"
                    accessor="firstName"
                    showLabel
                    type="text"
                  />
                </Col>
                <Col md={6}>
                  <CommonField
                    label="Last Name"
                    accessor="lastName"
                    showLabel
                    type="text"
                  />
                </Col>
              </Row>

              <CommonField
                label="Email"
                accessor="email"
                showLabel
                type="text"
              />

              <MaskedInput
                label={`Mobile`}
                accessor={`mobile`}
                mask="0499 999 999"
                showLabel
              />

              {profile.memberType === 2 && <GoogleAddressField />}

              <div>
                <FormikSubmitButton
                  label="Submit"
                  className="sw-btn-primary"
                  isLoading={isUpdating}
                  scrollToFirstError={true}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </EnhancedContentSection>
  );
};

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    const { updateProfile } = props;
    updateProfile(values);
  },
  displayName: "updateProfileForm",
});

const enhancedForm = formikEnhancer(UpdateProfile);

export default enhancedForm;

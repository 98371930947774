import React from "react";
import "./actual-terms.scss";

const ActualTerms = () => {
  return (
    <div className="text-justify actual-terms">
      <div className="font-weight-bold text-uppercase">The Hire Contract</div>

      <div className="mt-3">
        <ol>
          <li>
            <strong>Mr Traffic Hire</strong> agrees to hire Equipment to the
            Customer on terms set out in this contract including the attached
            Terms and Conditions of Hire. If the Customer wishes to hire
            Equipment the Customer must complete and sign a Hire Schedule and
            such other documents as Mr Traffic Hire may require. Each Hire
            Schedule is not a separate contract but forms a part of the hire
            agreement between Mr Traffic Hire and the Customer. Mr Traffic Hire
            may decline to enter into a Hire Schedule or to hire Equipment to
            the Customer at any time if it has reasonable cause to do so and it
            may terminate the hire agreement if the Customer breaches its
            obligations to Mr Traffic Hire.
          </li>
          <li>
            <strong>The Customer and the Guarantor(s)</strong> agree and
            acknowledge that Mr Traffic Hire has the right to amend this
            agreement including the terms and conditions of Hire from time to
            time and will give notice to the Customer and Guarantor of any
            amendments.
          </li>
          <li>
            By signing this contract the Customer and Guarantor(s) warrant that
            all information given to Mr Traffic Hire in connection with it is
            true.
          </li>
          <li>
            <strong>Credit</strong> - Mr Traffic Hire has the right to withdraw
            credit or vary the customer’s credit limit at any time and without
            the obligation to provide any reason.
          </li>
          <li>
            <strong>E-Tags and Tolls</strong> - If you hire a vehicle from Mr
            Traffic it is your responsibility to place an eTag in the car. If a
            tag is not placed within the vehicle and Mr Traffic is billed for
            tolls used, you will incur the cost of the tolls plus a 25% + GST
            administration fee for any costs incurred on top of the original fee
            charged by the toll company. Processing of these fees is a
            time-consuming process which we need to recoup costs for. If you
            have any questions, please contact our office on 1300mrtraffic.
          </li>
        </ol>
      </div>

      <div className="font-weight-bold text-uppercase mt-3">
        Interests and Costs
      </div>

      <div className="mt-3">
        Accounts are due and payable within thirty (30) days of invoice. Credit
        may be cancelled/suspended without notice if accounts are not paid by
        the due date. A late payment fee may be charged for overdue accounts.
        The Customer will pay to Mr Traffic Hire any commissions, legal costs
        (on a solicitor-client basis) or expenses paid by Mr Traffic Hire to its
        collection agents and/or lawyers in relation to the collection of any
        moneys owed to Mr Traffic Hire that are not paid within the terms of
        credit provided.
      </div>

      <div className="font-weight-bold text-uppercase mt-3">
        Joint and Several
      </div>

      <div className="mt-3">
        The Customer and Guarantor(s) agree that the rights, duties and
        obligations of the parties under this agreement binds them jointly and
        severally and that an agreement, representation or warranty for the
        benefit of two or more persons is for the benefit of them jointly and
        severally.
      </div>

      <div className="font-weight-bold text-uppercase mt-3">
        Use of Information
      </div>

      <div className="mt-3">
        Pursuant to the Privacy Act (1988), the Customer and each of its
        Directors and Guarantors authorise Mr Traffic Hire and its related
        bodies to obtain information about my/our personal or commercial credit
        worthiness and my/our bank or any trade referee disclosed at the
        application for credit and any credit provider and credit reporting
        agency for the purpose of assessing the application for credit, or in
        connection with any guarantee; to use, disclose, or exchange with other
        credit providers information about my/our personal or commercial credit
        arrangements in order to assess the application for credit (including
        whether to accept as guarantor or any person signing), monitor credit
        worthiness and collect overdue accounts; and to disclose the contents of
        a credit report by a credit reporting agency to Mr Traffic Hire’s
        solicitors or mercantile agents.
      </div>

      <div className="mt-3">
        <a href="/application-for-credit/terms-and-conditions/" target="_blank">
          View Full Terms and Conditions of Hire Contract
        </a>
      </div>
    </div>
  );
};

export default ActualTerms;

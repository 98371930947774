import { ActionTypes } from "./action-types";
import Service from "../services/service";
import {
  addNotification,
  addErrorNotification,
} from "redux/system/system-action-creators";
import { saveAs } from "file-saver";

const service = Service();

export const applyForCredit = (values, resetForm) => (dispatch) => {
  dispatch(applyForCreditRequest());

  service
    .applyForCredit(values)
    .then((resp) => {
      dispatch(applyForCreditSuccess());
      dispatch(
        addNotification(
          "Thank you, your application for credit has been submitted successfully."
        )
      );

      resetForm();

      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "credit-application.pdf");

      window.location.reload();
    })
    .catch((error) => {
      dispatch(applyForCreditFailure());
      dispatch(
        addErrorNotification(
          error,
          "Unable to submit the application for credit."
        )
      );
    });
};

const applyForCreditRequest = () => ({
  type: ActionTypes.APPLY_FOR_CREDIT_SUBMIT_REQUEST,
});
const applyForCreditSuccess = () => ({
  type: ActionTypes.APPLY_FOR_CREDIT_SUBMIT_SUCCESS,
});
const applyForCreditFailure = () => ({
  type: ActionTypes.APPLY_FOR_CREDIT_SUBMIT_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.APPLY_FOR_CREDIT_CLEAN_UP,
});

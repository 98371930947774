import { ActionTypes } from "./action-types";

const initialState = {
  isSubmittingForm: false,
};

function ApplicationForCreditReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APPLY_FOR_CREDIT_SUBMIT_REQUEST:
      return {
        ...state,
        isSubmittingForm: true,
      };
    case ActionTypes.APPLY_FOR_CREDIT_SUBMIT_REQUEST:
      return {
        ...state,
        isSubmittingForm: true,
      };
    case ActionTypes.APPLY_FOR_CREDIT_SUBMIT_SUCCESS:
    case ActionTypes.APPLY_FOR_CREDIT_SUBMIT_FAILURE:
      return {
        ...state,
        isSubmittingForm: false,
      };
    case ActionTypes.APPLY_FOR_CREDIT_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}

export default ApplicationForCreditReducer;

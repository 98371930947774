import { useEffect } from "react";
import { isNil } from "lodash";

const useInitialiseForm = (
  userId,
  isEdit,
  getUserDetails,
  cleanUp,
  setIsEdit,
  user,
  setValues,
  isFetching
) => {
  //Will decide whether a page is a create or update page
  //If update page, will call the getUserDetails()
  useEffect(() => {
    setIsEdit(isNil(userId) ? false : true);
    if (isEdit && !isFetching) {
      getUserDetails(userId);
    }
  }, [userId, isEdit]);

  //Resets the redux onPageLeave
  useEffect(() => {
    return () => cleanUp();
  }, []);

  //Will bind all user values
  useEffect(() => {
    if (!isNil(user)) {
      setValues({
        id: user.id,
        isEdit: true,
        firstName: user.firstName,
        lastName: user.lastName,
        memberType: user.memberType,
        email: user.email,
        mobile: user.mobile,
      });
    }
  }, [user]);
};

export default useInitialiseForm;

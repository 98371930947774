import items from "./../views/services/services-landing-page/items";

export const navLinks = [
  {
    title: "Home",
    href: "/",
    subLinks: [],
  },
  {
    title: "About",
    href: "/about/",
    subLinks: [],
  },
  {
    title: "Services",
    href: "/services/",
    subLinks: items.map((x) => {
      return {
        title: x.title,
        href: x.link,
        subLinks: [],
      };
    }),
  },
  {
    title: "News",
    href: "/news/",
    subLinks: [],
  },
  {
    title: "Contact",
    href: "/contact-us/",
    subLinks: [],
  },
];

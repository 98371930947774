import React from "react";
import EnhancedContentSection from "components/common/enhanced-content-section/index";
import "./full-terms.scss";

const FullTermsAndConditions = ({}) => {
  return (
    <div className="full-terms">
      <EnhancedContentSection
        pageTitle="MR TRAFFIC HIRE ABN 81 588 489 769 - TERMS AND CONDITIONS OF HIRE CONTRACT"
        isBlocking={false}
        showBackground={true}
      >
        <h4>1. Definitions</h4>
        <p>
          1.1. <strong>Mr Traffic Hire </strong>means Mr Traffic Hire, its
          successors and assigns or any person acting on behalf of and with the
          authority of Mr Traffic Hire.
        </p>
        <p>
          1.2. <strong>Hirer </strong>means the person/s or any person acting on
          behalf of and with the authority of the Hirer requesting Mr Traffic
          Hire to provide the Equipment as specified in any proposal, quotation,
          order, invoice or other documentation.
        </p>
        <p>
          1.3. <strong>Charges </strong>means the cost of the hire of Equipment
          (plus any GST where applicable) as agreed between Mr Traffic Hire and
          the Hirer.
        </p>
        <p>
          1.4. <strong>Equipment </strong>means any kind of equipment, vehicles
          or tools including but not limited to the following kinds of goods or
          goods suitable for the following kinds of uses: traffic management
          including road barriers; signage; vehicles including trucks, vans and
          trailers, and includes tools and parts and accessories for any of the
          foregoing supplied by Mr Traffic Hire to the Hirer. The Equipment
          shall be described on the Hire Agreement or any other commencement
          forms as provided by Mr Traffic Hire to the Hirer.
        </p>
        <p>
          1.5. <strong>Hire </strong>means any or all Hire supplied by Mr
          Traffic Hire to the Hirer and includes any advice or recommendations.
        </p>
        <p>
          1.6. <strong>Hire Period </strong>means the period commending on the
          date as shown in the agreement and ending on the date the Hirer
          returns the Equipment to Mr Traffic Hire. Hire Period may only be
          extended for one or more definite periods and in each case this can
          only be done if the Customer requests it and if Mr Traffic Hire
          agrees.
        </p>
        <p>
          1.7. <strong>Hire Schedule </strong>means any proposal, quotation,
          order, invoice or other documentation which Mr Traffic Hire may
          require the Customer to sign or accept in a way including particulars
          of the Equipment and the Hire Period.
        </p>
        <p>
          1.8. <strong>GST </strong>means Goods and Services Tax as defined
          within the “A New Tax System (Goods and Services Tax) Act 1999” (Cth).
        </p>
        <p>
          1.9. <strong>Kilometer Charge </strong>means the amount payable for
          the kilometers that a Vehicle has, in the reasonable opinion of Mr
          Traffic Hire, travelled during the Hire Period.
        </p>
        <p>
          1.10. <strong>Remote Area </strong>means any location which is more
          the 50 kilometers from the Mr Traffic Hire depot from where the
          Equipment is hired.
        </p>
        <h4>2. Obligations</h4>
        <p>2.1. Mr Traffic Hire will:</p>
        <p>
          2.1.1. Allow the Customer to take and use the Equipment for the Hire
          Period;
        </p>
        <p>
          2.1.2. Provide the Equipment to the Customer in good working order and
          without damage;
        </p>
        <p>
          2.1.3. Collect the Equipment within 48 hours of being requested to do
          so by the Customer at the expense of the Customer unless a Customer
          Pick Up Order is issued.
        </p>
        <p>2.2. The Customer must:</p>
        <p>
          2.2.1. Deliver the Equipment to the Mr Traffic Hire depot when it is
          due back;
        </p>
        <p>2.2.2. Return the Equipment in clean and in good working order;</p>
        <p>
          2.2.3. Operate the Equipment safely, in accordance with any
          manufacturer’s instructions and only for its intended use;
        </p>
        <p>
          2.2.4. Indemnify Mr Traffic Hire for all injury and/or damage to the
          extent caused or contributed to by the Customer to persons and
          property in relation to the Equipment and its operation;
        </p>
        <p>
          2.2.5. Ensure that any person collecting or taking delivery of
          Equipment on behalf of the Customer is authorised by the Customer.
        </p>
        <p>
          2.2.6. Ensure that all persons operating the Equipment are suitably
          trained and where necessary hold a current Certificate of Competency
          and are fully licensed;
        </p>
        <p>
          2.2.7. Have adequate insurance to cover all liabilities incurred as a
          result of the use of the Equipment;
        </p>
        <p>
          2.2.8. Have adequate insurance to cover theft, vandalism, misuse,
          accident or damage to the Equipment;
        </p>
        <p>
          2.2.9. Conduct a thorough hazard and risk assessment before using the
          Equipment and comply with all Occupational Health and Safety laws
          relating to the Equipment and its operation;
        </p>
        <p>
          2.2.10. Report and provide full details to Mr Traffic Hire of any
          accident or damage to the Equipment within 24 hours of the accident or
          damage occurring.
        </p>
        <p>
          2.3. The Customer must <u>NOT</u>;
        </p>
        <p>1.1.1. Tamper with, damage or repair the Equipment;</p>
        <p>1.1.2. Lose or part with possession of the Equipment;</p>
        <p>
          1.1.3. Allow the Equipment to be driven or used by any person if, at
          the time of them driving or using the Equipment, the Hirer or other
          person is not the holder of current driver's licence appropriate for
          the Equipment;
        </p>
        <p>
          1.1.4. Exceed the recommended or legal load and capacity limits of the
          Equipment;
        </p>
        <p>
          1.1.5. Use or carry any illegal, prohibited or dangerous substance in
          or on the Equipment.
        </p>
        <h4>3. Charges and Payment</h4>
        <p>3.1. The Customer must:</p>
        <p>
          3.1.1. On or before Commencement or as otherwise specifically agreed
          pay the Hire Charge.
        </p>
        <p>3.1.2. Immediately on request by Mr Traffic Hire pay:</p>
        <p align="left">
          (a) the new list price of any Equipment which is for whatever reason
          not returned to Mr Traffic Hire.
        </p>
        <p align="left">
          (b) all costs incurred by delivering and recovering possession of the
          Equipment;
        </p>
        <p align="left">
          (c) the full cost of repairing any damage to the Equipment;
        </p>
        <p align="left">
          (d) stamp duties, GST, any other taxes or duties, and all tolls,
          fines, penalties, levies or charges arising from the Customer’s use of
          the Equipment;
        </p>
        <p align="left">(e) all costs incurred in cleaning the Equipment;</p>
        <p align="left">
          (f) Interest for late payment of amounts owing by the customer;
        </p>
        <p align="left">
          (g) the Kilometer Charge and any additional Hire Charges;
        </p>
        <p>
          (h) the cost of fuels and consumables provided and not returned by the
          Customer;
        </p>
        <p>
          (i) any expenses and legal costs (including commission payable to a
          commercial agent) incurred by Mr Traffic Hire in enforcing this
          Contract due to the Customers default.;
        </p>
        <p>
          (j) all costs of repairing or replacing tyres, including road service.
        </p>
        <p>
          (k) If you hire a vehicle from Mr Traffic it is your responsibility to
          place an eTag in the car. If a tag is not placed within the vehicle
          and Mr Traffic is billed for tolls used, you will incur the cost of
          the tolls plus a 25% + GST administration fee for any costs incurred
          on top of the original fee charged by the toll company. Processing of
          these fees is a time-consuming process which we need to recoup costs
          for. If you have any questions, please contact our office on
          1300mrtraffic.
        </p>
        <p>
          3.1.3. Without limiting the ability of Mr Traffic Hire to recover all
          amounts owing to it, the Customer authorises Mr Traffic Hire to charge
          any amounts owing by the Customer to any credit card or account
          details of which are provided.
        </p>
        <h4>4. PPS Law</h4>
        <p align="left">
          4.1. This clause applies to the extent that this Contract provides for
          a ‘security interest’ for the purposes of the Personal Property
          Securities Act 2009 (Cth) (“PPS Law”).
        </p>
        <p align="left">
          4.2. References to PPS Law in this agreement include references to
          amended, replacement and successor provisions or legislation.
        </p>
        <p align="left">4.3. In this clause the following definitions apply:</p>
        <p>
          <strong>Collateral </strong>
          means the Equipment
        </p>
        <p>
          <strong>Grantor </strong>
          means the Customer
        </p>
        <br clear="all" />
        <p>
          <strong>Secured Money </strong>
          means money owing to Mr Traffic Hire pursuant to the Hire Contract
        </p>
        <p>
          <strong>Secured Party </strong>
          means Mr Traffic Hire
        </p>
        <p>
          4.4. The Grantor grants a security interest in the Collateral to the
          Secured Party to secure payment of the Secured Money.
        </p>
        <p>
          4.5. The Grantor must not do, or agree to do, any of the following
          unless it is permitted to do so by the Secured Party:
        </p>
        <p>
          (a) create or allow another interest to be created in any Collateral
        </p>
        <p>(b) dispose, or part with possession, of any Collateral.</p>
        <p>
          4.6. Mr Traffic Hire may register its security interest. The Customer
          must do anything such as obtaining consents and signing any documents
          which Mr Traffic Hire requires for the purposes of ensuring that Mr
          Traffic Hires’ security interest is enforceable and otherwise
          effective under the PPS Law;
        </p>
        <p>
          4.7. The rights of Mr Traffic Hire under this contract are in addition
          to and not in substitution for Mr Traffic Hires’ rights under other
          law (including the PPS Law).
        </p>
        <p>
          4.8. To the extent that Chapter 4 of PPSA applies to any security
          interest under this agreement, the following provisions of the PPS Law
          do not apply and, for the purposes of section 115 of the PPS Law are
          “contracted out” of this agreement in respect of all goods to which
          that section can be applied: sections 95, 96, 121(4), 125, 130,
          129(2)-(3), 132(3)(d), 132(4), 135, 142 and 143.
        </p>
        <p>
          4.9. The following provisions of the PPS Law: section 123, 126, 128,
          129, 134(1) confer rights on Mr Traffic Hire. Customer agrees that in
          addition to those rights, Mr Traffic Hire shall, if there is default
          by Customer, have the right to seize, purchase, take possession or
          apparent possession, retain, deal with or dispose of any goods, not
          only under those sections but also, as additional and independent
          rights, under this contract.
        </p>
        <p>
          4.10. The Customer waives its rights to receive a verification
          statement in relation to registration events in respect of commercial
          property under section 157 of the PPS Law.
        </p>
        <p>
          4.11. Mr Traffic Hire and the Customer agree not to disclose
          information of the kind that can be requested under section 275(1) of
          the PPS Law. The agreement in this sub-clause is made solely for the
          purpose of allowing to Mr Traffic Hire the benefit of section
          275(6)(a) and Mr Traffic Hire shall not be liable to pay damages or
          any other compensation or be subject to injunction in respect of any
          actual or threatened breach of this sub-clause.
        </p>
        <p>
          4.12. Customer must not dispose or purport to dispose of, or create or
          purport to create or permit to be created any ‘security interest’ (as
          defined in PPS Law) in the Equipment other than with the express
          written consent of Mr Traffic Hire.
        </p>
        <p>
          4.13. Customer must not lease, hire, bail or give possession
          (‘sub-hire’) of the Equipment to anyone else unless Mr Traffic Hire in
          its absolute discretion first consents in writing.
        </p>
        <p>
          4.14. To assure performance of its obligations under this agreement,
          the Customer hereby gives Mr Traffic Hire an irrevocable power of
          attorney to do anything Mr Traffic Hire considers the Customer should
          do under this agreement. Mr Traffic Hire may recover from Customer the
          cost of doing anything under this clause 4, including registration
          fees.
        </p>
        <h4>5. Exclusion of Warranties and Liabilities.</h4>
        <p>
          5.1. Where the Australian Consumer Law applies and the goods are not
          of a kind ordinarily acquired for personal domestic or household use
          or consumption, Mr Traffic Hire‘s liability in respect of any
          guarantee is limited to the replacement or repair of the goods, or the
          cost of having the goods repaired or replaced.
        </p>
        <p>
          5.2. To the extent that the Australian Consumer Law (or any other law
          which cannot be excluded) does not apply. Mr Traffic Hire makes no
          representations and gives no warranties other than those set out in
          these Hire Contract Conditions, and will not be liable to the Customer
          for any damages, costs or other liabilities whatsoever (including for
          consequential loss) in relation to the hiring of the Equipment by the
          Customer.
        </p>
        <h4>6. Remote Hire</h4>
        <p>
          6.1. Where the Equipment is at any time hired by the Customer to be
          located in the Remote Area, the following clauses will also apply, in
          addition to the obligations of the Customer under Clause 3 and
          elsewhere in these Hire Contract Conditions;
        </p>
        <p>
          6.1.1. The Customer will pay an additional charge for any delivery,
          servicing and repair of the Equipment, and for any other attendance at
          the Remote Area by Mr Traffic Hire (‘Remote Area Charges’). The Remote
          Area Charges will be calculated on a per kilometer rate travelled by
          Mr Traffic Hire staff to and from the Remote Area, plus labour costs
          per staff member per hour including travelling times;
        </p>
        <p>
          6.1.2. Multiple items of Equipment hire by a Customer on the one site
          will only be charged for one call out fee;
        </p>
        <p>
          6.1.3. The Customer is responsible at its cost for daily maintenance
          and care of all Equipment in its possession, including daily checking
          of all fluids (fuel, oil, water, battery levels etc.) and general
          tightening of any loose nuts, bolts, belts or fittings and lubrication
          of all grease points.
        </p>
        <h4>7. Breach of Hire Contract by Customer</h4>
        <p>
          7.1. If the Customer breaches any clause whatsoever of this Contract
          and does not remedy the breach within 5 business days of the breach,
          or becomes bankrupt, insolvent or ceases business then;
        </p>
        <p>
          7.1.1. Mr Traffic Hire shall be entitled to terminate this Contract
          and sue for recovery of all monies owing by the Customer; and/or
        </p>
        <p>
          7.1.2. repossess the Equipment and is authorized to enter any premises
          where the Equipment is located to do so.
        </p>
        <p>
          7.2. The Customer indemnifies Mr Traffic Hire in respect of any
          damages, costs or loss, to the extent caused or contributed to by the
          customer resulting from a breach by the Customer of any provision of
          this Contract.
        </p>
        <h4>8. Disputes</h4>
        <p>
          8.1. The Customer must immediately check all Hire Charges, and any
          disputes in relation to those Hire Charges must be communicated to Mr
          Traffic Hire in writing within 20 business days of the Hire Contract
          date. In the event that no communication is received from the Customer
          within that 20 business days, the Hire Charges are deemed to be
          accepted by the Customer.
        </p>
        <h4>9. Privacy</h4>
        <p>
          9.1. Mr Traffic Hire will comply with the Australian Privacy
          Principles in all dealings with Customers.
        </p>
        <h4>10. Governing Law</h4>
        <p>
          10.1. These terms and conditions and any contract to which they apply
          shall be governed by the laws of Victoria, the state in which Mr
          Traffic Hire has its principal place of business.
        </p>
      </EnhancedContentSection>
    </div>
  );
};

export default FullTermsAndConditions;

import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  user: null,
};

export default function CreateUpdateUserReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}

import React from "react";
import LinksCard from "./../components/link-card";
import getAdminLinks from "./../links/admin-links";
import PageTagging from "components/common/page-tagging/page-tagging";

const LinksComponent = ({ pageTitle, hideHeading }) => {
  return (
    <>
      <LinksCard styleName="link-card-dark" linksArray={getAdminLinks()} />
      <PageTagging title={pageTitle} description="" />

      {!hideHeading && (
        <div className="mt-3">
          {pageTitle && (
            <>
              <h2>{pageTitle}</h2>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default LinksComponent;

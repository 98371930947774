import React from "react";

const View = ({ image }) => {
  return (
    <a href={`${image.url}`} target="_blank">
      <div
        className="home-partner"
        style={{
          backgroundImage: "url('/images/partners/" + image.name + "')",
          cursor: "pointer",
        }}
      ></div>
    </a>
  );
};

export default View;

import React from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import ContentSection from "./../../components/common/content-section";
import "./about.scss";
import ButtonFx from "./../../components/common/button-fx";
import PageTagging from "components/common/page-tagging/page-tagging";

const About = () => {
  return (
    <>
      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={true}
        pageTitle="About Us"
        bgClass="sw-bg-std-grey"
      >
        <PageTagging
          title="About Us | Specialised Traffic Equipment Hire Melbourne"
          description="Mr Traffic Hire offers specialised traffic equipment hire on any scale, such as large infrastructure road works and projects to single Variable Messaging Signs for advertising."
        />

        <div className="about">
          <img
            src="/images/gp-image.jpg"
            alt="Melbourne GP Arrowboard"
            className="gp-image shadow"
          />

          <div className="mt-3 text-area">
            <p>
              Mr Traffic Hire offers specialised traffic equipment hire on any
              scale, such as large infrastructure road works and projects to
              single Variable Messaging Signs (VMS) for advertising. We have a
              full range of traffic safety equipment.{" "}
            </p>

            <p>
              With over 25 years combined experience in the Hire industry Mr
              Traffic Hire can offer our clients the most up to date solutions
              for managing your needs, combined with our vast industry
              knowledge, makes us confident that we can provide solutions to our
              clients.
            </p>

            <p>
              At Mr Traffic Hire we endeavour to work closely with all our
              clients to find a solution that works and with our expert
              knowledge and understanding of current legislation, in addition to
              providing the highest level of quality, service and value, Mr
              Traffic Hire is confident we can help you achieve your targets.
            </p>

            <p>
              Mr Traffic Hire is available 24 hours a day, 7 days a week and we
              will work within your timeframes and budget to make it happen.
            </p>
          </div>
        </div>
      </EnhancedContentSection>
      <ContentSection>
        <p>
          <h2>Our Team</h2>
        </p>
        <div className="row">
          <div className="col-md-9 col-12">
            <h5>Jamie Haitas</h5>
            <strong>General Manager</strong>
            <p>
              Jamie has 10 years’ experience in the traffic hire industry. Jamie
              commenced with Kennards Traffic in 2010.
            </p>
            <p>
              Whilst at Kennards Jamie expanded the hire fleet from 20 VMS to
              150, acquired a fleet of over 2000 barriers, built the first hire
              TMA in Australia and grew this to a fleet of 70 Australia wide.
              During this period Jamie also expanded the business from $4
              million to $20 million turnover.
            </p>
            <p>
              In 2014 Jamie was approached by RSEA to help grow the business.
              Jamie relocated RSEA to a more suitable premises and expanded the
              VMS fleet from 40 to 100. Jamie successfully procured the first
              Zoneguard steel barrier job on the Tullamarine Freeway upgrade,
              supplied and installed 10.5kms of steel barrier over 3 months and
              had in out for 2.5 years.
            </p>
            <p>
              In 2017 Jamie was approached by J1LED to build their Victorian
              business. Jamie sold 40 TMA’s into the Melbourne market. Whilst
              working for J1LED it became evident that with Jamie’s experience
              and expertise he should establish and commence his own hire
              business.
            </p>
            <p>
              In October 2017 Jamie formed Mr Traffic Hire Pty Ltd servicing the
              Traffic Management Industry with specialised traffic equipment and
              the company continues to grow.
            </p>
          </div>
          <div className="col-md-3 col-12">
            <img
              src="/images/jamie.jpg"
              alt="Jamie"
              className="gp-image shadow"
            />
            <p></p>
            <p>
              <a
                href="https://www.linkedin.com/in/jamie-haitas-98113357/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/linkedin-logo.png"
                  alt="LinkedIn"
                  className="col-md-5"
                />
              </a>
            </p>
          </div>
        </div>
      </ContentSection>
      <ContentSection bgClass="sw-bg-std-grey">
        <div className="about">
          <a
            href="https://www.therentaljournal.com/chattingwithjamiehaitas"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/podcast.jpg"
              alt="Jamie's Podcast"
              className="gp-image shadow"
            />
          </a>

          <div className="mt-3 text-area">
            <p>
              <h2>Jamie's Podcast</h2>
            </p>
            <p>
              &ldquo;The ups and downs of my hire journey over the last 13
              years&rdquo;
            </p>
            <p>
              Jamie opens up about some of the struggles he went through early
              in his career from over working and putting too much pressure on
              himself.
            </p>
            <p>
              Check out this link to hear Jamie talking about his path to Mr
              Traffic Hire.
            </p>
            <ButtonFx
              className="mt-blue-mini-button"
              href=""
              onClick={() =>
                window.open(
                  "https://www.therentaljournal.com/chattingwithjamiehaitas"
                )
              }
            >
              Listen Now
            </ButtonFx>
          </div>
        </div>
      </ContentSection>
    </>
  );
};

export default About;

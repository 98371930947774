import * as Yup from "yup";

const registrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("Last name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Invalid email address"),
  mobile: Yup.string()
    .required("Mobile is required.")
    .matches(/^$|\s+/, "Mobile is required."),
  companyName: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string()
      .min(2, "Company name must have at least 2 characters.")
      .required("Company name is required."),
    otherWise: Yup.string().notRequired,
  }),
  abn: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string()
      .min(14, "Invalid australian business number format.")
      .required("Australian business number is required."),
    otherWise: Yup.string().notRequired,
  }),
  streetNumber: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string().required("Street number is required."),
    otherWise: Yup.string().notRequired,
  }),
  streetName: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string().required("Street name is required."),
    otherWise: Yup.string().notRequired,
  }),
  city: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string().required("City is required."),
    otherWise: Yup.string().notRequired,
  }),
  state: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string().required("State is required."),
    otherWise: Yup.string().notRequired,
  }),
  postcode: Yup.string().when("memberType", {
    is: (val) => val === 2,
    then: Yup.string().required("Post code is required."),
    otherWise: Yup.string().notRequired,
  }),
});

export default registrationValidationSchema;

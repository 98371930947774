import CustomerInformationValues from "./sub-initial-values/customer-information-initial-values";
import DetailsInitialValues from "./sub-initial-values/details-initial-values";
import AccountsPayableContactValues from "./sub-initial-values/accounts-payable-contact-initial-values";
import TermsAndConditionsInitialValues from "./sub-initial-values/terms-conditions-initial-values";

const enrolmentInitialValues = {
  section1: CustomerInformationValues,
  section2: DetailsInitialValues,
  section3: AccountsPayableContactValues,
  section4: TermsAndConditionsInitialValues,
};

export default enrolmentInitialValues;

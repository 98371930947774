import React from "react";
import CollapsibleItem from "components/common/collapsible-panel/collapsible-item";
import CommonFastField from "components/common/common-fast-field";
import SectionNavigation from "components/common/collapsible-panel/section-navigation";
import DynamicRadioGroup from "components/common/dynamic-radio-group";
import sendBy from "constants/send-by";
import { Col, Row } from "reactstrap";
import shallowequal from "shallowequal";

const AccountsPayableContact = ({
  handleInit,
  collapseState,
  handleToggle,
  idsRegistered,
  setToggle,
  setToggleAll,
}) => {
  return (
    <CollapsibleItem
      id="section3"
      key="3"
      title="Accounts Payable Contact"
      init={handleInit}
      collapseState={collapseState}
      toggle={handleToggle}
    >
      <Row>
        <Col xs="12" lg="9">
          <CommonFastField
            label="Name"
            accessor="section3.name"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="Phone"
            accessor="section3.phone"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <CommonFastField
            label="Email"
            accessor="section3.email"
            showLabel
            type="text"
            overridePlaceholder={true}
          />

          <DynamicRadioGroup
            label="How do you require your invoice to be sent by?"
            accessor="section3.sendBy"
            direction="horizontal"
            options={sendBy}
          />

          <SectionNavigation
            sectionId="section3"
            idsRegistered={idsRegistered}
            setToggle={setToggle}
            setToggleAll={setToggleAll}
          />
        </Col>
      </Row>
    </CollapsibleItem>
  );
};

function valuesAreEqual(prevProps, nextProps) {
  return (
    shallowequal(prevProps.values.section3, nextProps.values.section3) &&
    prevProps.collapseState.section3 === nextProps.collapseState.section3
  );
}

export default React.memo(AccountsPayableContact, valuesAreEqual);

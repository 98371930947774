import { useEffect } from "react";

export const useExpandOnError = ({
  errors,
  isSubmitting,
  setBatchToggleByState
}) => {
  useEffect(() => {
    if (isSubmitting && errors) {
      //Will transform all errors into named arrays.
      let arrayedErrors = Object.entries(errors);

      //Will look for sections with errors and error array of sections
      //This is the array that contains the sections with errors.
      let toggleOpenArr = arrayedErrors
        .filter(error => error[1] != undefined)
        .map(error => error[0]);

      //Will look for sections that are not included in toggleOpenArr
      //This is the array that contains the sections without errors.
      let toggleCloseArr = arrayedErrors
        .filter(error => !toggleOpenArr.find(err => error[0] == err))
        .map(error => error[0]);

      setBatchToggleByState(toggleOpenArr, toggleCloseArr);
    }
  }, [isSubmitting]);
};

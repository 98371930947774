import ActionTypes from "./action-types";
import LoginService from "../services/service";
import {
  setProfile,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";

export const localLogin = (userName, password, history) => {
  return (dispatch, getState) => {
    const service = LoginService();
    dispatch(loginRequest());
    service
      .login(userName, password)
      .then((resp) => {
        dispatch(loginSuccess());
        dispatch(setProfile(resp));

        history.push("/");
      })
      .catch((error) => {
        dispatch(loginFailure());
        dispatch(
          addErrorNotification(
            error,
            "Sorry, your user name or password is incorrect or we were unable to connect to the server."
          )
        );
      });
  };
};

const loginRequest = () => ({
  type: ActionTypes.LOGIN_REQUEST,
});

const loginSuccess = (data) => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: data,
});

const loginFailure = () => ({
  type: ActionTypes.LOGIN_FAILURE,
});

export const loginInit = () => ({
  type: ActionTypes.LOGIN_INIT,
});

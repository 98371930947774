import AdminRoutes from "./admin-routes";
import Playground from "views/playground";

import Home from "views/home";
import Faqs from "../views/faqs";
import ContactUs from "views/contact-us";
import GetQuote from "views/quote";
import RegistrationPage from "views/register";
import LoginPage from "views/login";
import Logout from "views/logout";
import ForgotPassword from "views/forgot-password";
import ResetPassword from "views/reset-password";
import UpdateProfile from "views/update-profile";
import ContentPage from "views/content-page";
import ServicesLandingPage from "views/services/services-landing-page";
import About from "views/about";
import News from "views/news";
import ArrowBoards from "views/services/arrow-boards";
import Portaboom from "views/services/portaboom";
import VMSBoards from "views/services/vms-boards";
import PortableTrafficLights from "views/services/portable-traffic-lights";
import TravelTimes from "views/services/travel-times";
import VariableSpeedLimitSigns from "views/services/variable-speed-limit-signs";
import TruckMountedAttenuators from "views/services/truck-mounted-attenuators";
import HighwayTrucks from "views/services/highway-trucks";
import TrafficUtes from "views/services/traffic-utes";
import VmsUtes from "views/services/vms-utes";
import CustomerInformation from "views/application-for-credit";
import FullTermsAndConditions from "views/application-for-credit/collapsible-items/actual-terms/full-terms";

const ModuleRoutes = [
  ...AdminRoutes, // Admin Routes
  // Test Routes
  {
    path: "/playground/:path",
    name: "Playground",
    component: Playground,
    privateRoute: false,
  },
  // Core Routes
  {
    path: "/",
    name: "Home",
    component: Home,
    privateRoute: false,
  },
  {
    path: "/faqs/",
    name: "FAQs",
    component: Faqs,
    privateRoute: false,
  },
  {
    path: "/contact-us/",
    name: "Contact Us",
    component: ContactUs,
    privateRoute: false,
  },
  {
    path: "/get-a-quote/",
    name: "Get Quote",
    component: GetQuote,
    privateRoute: false,
  },
  {
    path: "/register/",
    name: "Registration",
    component: RegistrationPage,
    privateRoute: false,
  },
  {
    path: "/login/",
    name: "Login",
    component: LoginPage,
    privateRoute: false,
  },
  {
    path: "/logout/",
    name: "Logout",
    component: Logout,
    privateRoute: false,
  },
  {
    path: "/forgot-password/",
    name: "Forgot Password",
    component: ForgotPassword,
    privateRoute: false,
  },
  {
    path: "/reset-password/",
    name: "Reset Password",
    component: ResetPassword,
    privateRoute: false,
  },
  {
    path: "/update-profile/",
    name: "Update Profile",
    component: UpdateProfile,
    privateRoute: true,
  },
  {
    path: "/about/",
    component: About,
    privateRoute: false,
  },
  {
    path: "/news/",
    component: News,
    privateRoute: false,
  },
  {
    path: "/services/arrow-boards/",
    component: ArrowBoards,
    privateRoute: false,
  },
  {
    path: "/services/portaboom/",
    component: Portaboom,
    privateRoute: false,
  },
  {
    path: "/services/vms-boards/",
    component: VMSBoards,
    privateRoute: false,
  },
  {
    path: "/services/portable-traffic-lights/",
    component: PortableTrafficLights,
    privateRoute: false,
  },
  {
    path: "/services/travel-times/",
    component: TravelTimes,
    privateRoute: false,
  },
  {
    path: "/services/variable-speed-limit-signs/",
    component: VariableSpeedLimitSigns,
    privateRoute: false,
  },
  {
    path: "/services/truck-mounted-attenuators/",
    component: TruckMountedAttenuators,
    privateRoute: false,
  },
  {
    path: "/services/highway-trucks/",
    component: HighwayTrucks,
    privateRoute: false,
  },
  {
    path: "/services/traffic-utes/",
    component: TrafficUtes,
    privateRoute: false,
  },
  {
    path: "/services/vms-utes/",
    component: VmsUtes,
    privateRoute: false,
  },
  {
    path: "/services/",
    component: ServicesLandingPage,
    privateRoute: false,
  },
  {
    path: "/application-for-credit/",
    component: CustomerInformation,
    privateRoute: false,
  },
  {
    path: "/application-for-credit/terms-and-conditions/",
    component: FullTermsAndConditions,
    privateRoute: false,
  },

  // User Routes

  // Catch all Route
  {
    path: "*",
    component: ContentPage,
    privateRoute: false,
  },
];

export default ModuleRoutes;

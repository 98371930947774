import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import ManualAddressEntry from "./address-entry";
import { Field } from "formik";
import { BeatLoader } from "react-spinners";
import "./google-address-field.scss";
import handleGoogleAddressResult from "./google-autocomplete-handlers";
import isNil from "lodash/isNil";
import GoogleAddressSubmissionValidationHook from "./hooks/submission-hook";
import PropTypes from "prop-types";

//Prefix - section1
//Accessor - postalAddress

const GoogleAddressField = ({
  allowAutoSearch,
  accessor,
  hasData,
  label,
  prefix,
}) => {
  const [isManualEntry, toggleManualEntry] = useState(false);
  const [isAdressRequesting, toggleAddressRequestLoader] = useState(false);
  const autoSearch = allowAutoSearch === undefined ? true : allowAutoSearch;
  useEffect(() => toggleManualEntry(hasData ? true : false), []);

  const getDefaultValue = (values) => {
    if (accessor) {
      return isNil(values[accessor].fullAddress)
        ? ""
        : values[accessor].fullAddress;
    }

    return isNil(values.fullAddress) ? "" : values.fullAddress;
  };

  const getDefaultValueWithPrefix = (values) => {
    if (values[prefix] && accessor in values[prefix]) {
      return values[prefix][accessor].fullAddress;
    }
  };

  return (
    <div className="mb-2 google-field-address">
      {/* Google auto-suggest address field */}
      <div className={isManualEntry || !autoSearch ? "hide-address" : ""}>
        <Label className="font-weight-bold">
          {label ? `${label} Address` : "Address"}
        </Label>
        <Field>
          {({ form }) => (
            <div>
              <GoogleAddressSubmissionValidationHook
                form={form}
                toggleManualEntry={toggleManualEntry}
                accessor={accessor}
                prefix={prefix}
              />
              <Autocomplete
                defaultValue={
                  prefix
                    ? getDefaultValueWithPrefix(form.values)
                    : getDefaultValue(form.values)
                }
                className="form-control w-100"
                placeholder="Start typing address"
                onPlaceSelected={({}, { value }) => {
                  handleGoogleAddressResult(
                    value,
                    form,
                    toggleManualEntry,
                    toggleAddressRequestLoader,
                    accessor,
                    prefix
                  );
                }}
                types={["address"]}
                componentRestrictions={{ country: "au" }}
              />
              {isAdressRequesting && (
                <div className="mt-2">
                  <BeatLoader
                    sizeUnit={"px"}
                    size={15}
                    color={"#6cb8e7"}
                    loading={isAdressRequesting}
                  />
                </div>
              )}
            </div>
          )}
        </Field>
      </div>

      {/* Only visible when the user chose to enter address manually*/}
      {(isManualEntry || !autoSearch) && (
        <div className="mt-2">
          <ManualAddressEntry
            accessor={accessor}
            label={label}
            prefix={prefix}
          />
        </div>
      )}

      {/* Link to hide or show the auto-suggest field and manual address entry */}
      {!isAdressRequesting && autoSearch && (
        <div className="mt-2 mb-3">
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              toggleManualEntry(!isManualEntry);
            }}
          >
            <small>
              {isManualEntry
                ? "Enter address using auto-suggest address box."
                : "Enter address manually."}
            </small>
          </a>
        </div>
      )}
    </div>
  );
};

GoogleAddressField.propTypes = {
  allowAutoSearch: PropTypes.bool,
  accessor: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  hasData: PropTypes.bool,
  label: PropTypes.string,
};

export default GoogleAddressField;

const mrTrafficSource = [
  "Facebook",
  "Instagram",
  "Linkedin",
  "Word Of Mouth",
  "Google Search",
  "Other",
];

const getSourceAsArrayOfObjects = () => {
  return mrTrafficSource.map((x) => {
    return {
      value: x,
      label: x,
    };
  });
};

export { mrTrafficSource as default, getSourceAsArrayOfObjects };

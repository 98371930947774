export const partners = [
  { name: "kwc.jpg", url: "https://kidswithcancer.org.au/" },
  { name: "solvable.png", url: "https://www.solvable.com.au/" },
  { name: "tmaa.jpg", url: "https://tmaa.asn.au/" },
  { name: "hria.png", url: "https://hireandrental.com.au/" },
  {
    name: "rental-journal-podcast.jpg",
    url: "https://www.therentaljournal.com/",
  },
  {
    name: "shots-creative-logo.png",
    url: "http://instagram.com/shotscreativestudio",
  },
  { name: "creditorwatch.png", url: "https://creditorwatch.com.au/" },
];

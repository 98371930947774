import * as Yup from "yup";

const AccountsPayableContactValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  phone: Yup.string().required("Phone is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Invalid email address."),
  sendBy: Yup.string().required(
    "Please indicate how you require your invoice to be sent by."
  ),
});

export default AccountsPayableContactValidationSchema;

import React from "react";
import "./footer.scss";
import { Row, Col, Container } from "reactstrap";
import ButtonFx from "./../common/button-fx";
import { useHistory } from "react-router-dom";

const Footer = () => {
  let history = useHistory();

  return (
    <footer className="w-100 py-5 d-flex flex-column shadow-md">
      <Container
        fluid
        className="container d-flex flex-column w-100 justify-content-center align-items-center mb-3"
      >
        <Row className="w-100 justify-content-md-between" form noGutters>
          <Col xs="12" md="7">
            <h3>For Enquiries:</h3>

            <div className="mt-3">
              <i className="fas fa-phone mr-2" />
              <span className="text-white">
                <a href="tel:1300678723">1300 MRTRAFFIC</a>
              </span>
            </div>
            <div className="mt-1">
              <div className="mt-1">
                <i className="fas fa-envelope mr-2" />
                <span className="text-white email-link">
                  <a href="mailto:hire@mrtraffichire.com.au">
                    hire@mrtraffichire.com.au
                  </a>
                </span>
              </div>
              <div className="mt-1">
                <i className="fas fa-map-marker-alt mr-2" />
                <span className="text-white">
                  10 Everaise Court, Laverton North, VIC 3206
                </span>
              </div>
              <div className="mt-1 text-white">
                <i className="fas fa-dollar-sign mr-2" />
                <a href="/application-for-credit/">Application for Credit</a>
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            md="5"
            className="buttons d-flex flex-xs-row flex-row flex-md-column justify-content-xs-start justify-content-md-center align-items-end"
          >
            <div className="quote">
              <ButtonFx
                className="mt-aqua-mini-button"
                onClick={() => history.push("/contact-us/")}
              >
                <i className="fas fa-envelope mr-1" /> Contact Us
              </ButtonFx>
            </div>
            <div className="mt-2">
              <ButtonFx
                className="mt-aqua-mini-button"
                onClick={() => history.push("/get-a-quote/")}
              >
                <i className="fas fa-calculator mr-2" />
                Get a Quote
              </ButtonFx>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="text-white w-100 footnote py-2 shadow-lg">
        <div className="col text-center">
          &copy; {new Date().getFullYear()} Mr Traffic Hire. All Rights
          Reserved. Website by{" "}
          <a
            href="https://www.solvable.com.au"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-item-style"
            title="Solvable provides business efficiency &amp; client value through creating cost effective, innovative solutions utilising cutting edge technologies, designed with a philosophy that &ldquo;Everything is Solvable, Anything is Possible&rdquo;."
          >
            Solvable.
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

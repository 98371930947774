import ActionTypes from "./action-types";
import Service from "./../services/service";
import {
  addNotification,
  addErrorNotification,
} from "./../../../../../../redux/system/system-action-creators";

let service = Service();

export const createUser = (model, history) => (dispatch) => {
  dispatch(createUpdateUserRequest());
  service
    .createUser(model)
    .then(() => {
      dispatch(createUpdateUserSuccess());
      history.push(`/admin/manage-users/`);
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to register the user."));
      dispatch(createUpdateUserFailure());
    });
};

export const updateUser = (model) => (dispatch) => {
  dispatch(createUpdateUserRequest());
  service
    .updateUser(model)
    .then(() => {
      dispatch(createUpdateUserSuccess());
      dispatch(addNotification("The user details have been updated."));
    })
    .catch((error) => {
      dispatch(
        addErrorNotification(error, "Unable to update the user details.")
      );
      dispatch(createUpdateUserFailure());
    });
};

const createUpdateUserRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_REQUEST,
});

const createUpdateUserSuccess = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_SUCCESS,
});

const createUpdateUserFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_FAILURE,
});

export const getUserDetails = (userId) => (dispatch) => {
  dispatch(getUserDetailsRequest());
  service
    .getUserDetails(userId)
    .then((data) => dispatch(getUserDetailsSuccess(data)))
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to get the user details."));
      dispatch(getUserDetailsFailure());
    });
};

const getUserDetailsRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_REQUEST,
});

const getUserDetailsSuccess = (payload) => ({
  type: ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_SUCCESS,
  payload,
});

const getUserDetailsFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_CLEAN_UP,
});

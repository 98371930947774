import React from "react";
import { Redirect } from "react-router-dom";

const withAdminHoc = (wrappedComponent, memberType) => {
  if (memberType && memberType !== 3) return <Redirect to="/" />;

  return wrappedComponent;
};

export default withAdminHoc;

import australian_states from "./australian-states";
import addressComponents from "./address-components";
import getAddress from "./../../../core/utilities/address-service";

const handleGoogleAddressResult = async (
  value,
  form,
  toggleManualEntry,
  toggleAddressRequestLoader,
  baseAccessor,
  prefix
) => {
  //Start the beatloader animation
  toggleAddressRequestLoader(true);
  // Get results
  let resp = await getAddress(value);
  //Map Result
  let result = mapAddressResults(resp);
  // Fill the manual entry form fields with the parsed result

  if (prefix) {
    setAddressFieldValues(
      result,
      form.setFieldValue,
      `${prefix}.${baseAccessor}`
    );

    form.setFieldValue(
      baseAccessor ? `${prefix}.${baseAccessor}.fullAddress` : `fullAddress`,
      value
    );
  } else {
    setAddressFieldValues(result, form.setFieldValue, baseAccessor);

    form.setFieldValue(
      baseAccessor ? `${baseAccessor}.fullAddress` : `fullAddress`,
      value
    );
  }

  //Identify what fields are required. This is based on the isRequired prop from the address components list.
  let requiredFieldsAccesors = getRequiredFieldsAccessors();
  //Get the values of the required fields.
  let requiredFieldsValues = getRequiredFieldsValues(
    result,
    requiredFieldsAccesors
  );
  //Identify the important fields with missing values and map them accordingly.
  let missingFields = getMissingFields(requiredFieldsValues);
  //Execute this if there is a missing important field

  if (prefix) {
    checkErrors(
      missingFields,
      form,
      toggleManualEntry,
      `${prefix}.${baseAccessor}`
    );
  } else {
    checkErrors(missingFields, form, toggleManualEntry, baseAccessor);
  }

  //Stop the beatloader animation
  toggleAddressRequestLoader(false);
};

const mapAddressResults = (response) => {
  return Object.entries(response).map((res) => {
    return {
      name: res[0],
      value: res[1],
    };
  });
};

const setAddressFieldValues = (result, setFieldValue, baseAccessor) => {
  addressComponents.forEach(({ accessor }) => {
    let name = accessor;
    let value = result.find((c) => c.name === name).value;
    setFieldValue(
      baseAccessor ? `${baseAccessor}.${accessor}` : accessor,
      accessor === "state"
        ? australian_states.find((state) => state.value === value)
        : value
    );
  });
};

//Error handler Section
const getRequiredFieldsAccessors = () =>
  addressComponents
    .filter((component) => component.isRequired)
    .map(({ accessor }) => accessor);

const getRequiredFieldsValues = (result, requiredFieldsAccesors) => {
  return result.filter(({ name }) => {
    return requiredFieldsAccesors.find((accessor) => accessor === name);
  });
};

const getMissingFields = (requiredFieldsValues) =>
  requiredFieldsValues
    .filter(({ value }) => value === "")
    .map(({ name }) => {
      return {
        accessor: name,
        label: addressComponents.find(({ accessor }) => accessor === name).name,
      };
    });

const checkErrors = (
  missingFields,
  { setFieldError, setFieldTouched },
  toggleManualEntry,
  baseAccessor
) => {
  if (missingFields.length > 0) {
    missingFields.forEach(({ accessor, label }) => {
      let base = baseAccessor ? `${baseAccessor}.${accessor}` : accessor;
      //Will set the error message of the missing required field.
      setFieldError(base, `${label} is required.`);
      //Will set the 'touched' property missing required field.
      setFieldTouched(base, true);
    });
    //Show the address components
    toggleManualEntry(true);
  }
};
//Error handler Section

export default handleGoogleAddressResult;

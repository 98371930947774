const columns = [
  {
    accessor: "id",
    show: false,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    searchable: true,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    searchable: true,
  },
  {
    Header: "Email",
    accessor: "email",
    searchable: true,
  },
  {
    Header: "Mobile",
    accessor: "mobile",
    searchable: true,
  },
  {
    Header: "User Type",
    accessor: "userType",
    searchable: true,
  },
  {
    Header: "Date Joined",
    accessor: "registationDate",
    searchable: true,
    dateFormat: "DDMMYYHHmm",
  },
  {
    Header: "Active",
    accessor: "isActive",
    searchable: true,
  },
];

export default columns;

import React from "react";
import { Form } from "formik";
import ButtonFx from "./../../components/common/button-fx";
import { Row, Col } from "reactstrap";
import CommonField from "./../../components/common/common-field";
import MaskedInput from "./../../components/common/masked-input";
import Password from "./../../components/common/password-component";
import { Redirect, Link } from "react-router-dom";
import getRedirectionPath from "./../../core/utilities/location-service";
import Auth from "./../../core/auth/auth";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";

const RegistrationPage = ({ location, isLoading }) => {
  let pageTitle = "Register";
  let redirectPath = getRedirectionPath(location);
  if (Auth.isAuthenticated()) return <Redirect to={redirectPath} />;

  return (
    <EnhancedContentSection pageTitle={pageTitle} isBlocking={false}>
      <div>
        <div>
          <Link to="/login/" className="sw-link">
            <ButtonFx className="sw-btn-secondary">
              Already have an account? Login Now
            </ButtonFx>
          </Link>
        </div>
      </div>

      <div className="mt-3 mb-5">
        <Form className="flex-fill mt-4">
          <Row>
            <Col xs="12" lg="9">
              <Row form>
                <Col md={6}>
                  <CommonField
                    label="First Name"
                    accessor="firstName"
                    showLabel
                    type="text"
                  />
                </Col>
                <Col md={6}>
                  <CommonField
                    label="Last Name"
                    accessor="lastName"
                    showLabel
                    type="text"
                  />
                </Col>
              </Row>

              <CommonField
                label="Email"
                accessor="email"
                showLabel
                type="text"
              />

              <MaskedInput
                label={`Mobile`}
                accessor={`mobile`}
                mask="0499 999 999"
                showLabel
              />

              {/* Password Fields */}
              <Password />

              <div className="check-box">
                <CommonField
                  label={`Do you accept our `}
                  accessor={`acceptedTheTerms`}
                  showLabel
                  type={`checkbox`}
                >
                  <a
                    href="/terms-and-conditions/"
                    target="_blank"
                    className="sw-link"
                  >
                    terms and conditions
                  </a>
                  ?
                </CommonField>
              </div>

              <div>
                <FormikSubmitButton
                  label="Submit"
                  className="sw-btn-primary"
                  isLoading={isLoading}
                  scrollToFirstError={true}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </EnhancedContentSection>
  );
};

export default RegistrationPage;

const userTypes = [
  {
    value: 1,
    label: "Regular",
  },
  {
    value: 3,
    label: "Admin",
  },
];

export default userTypes;

//label
//accessor
//options [{label, value}]
//direction (vertical, horizontal)

import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Field } from "formik";
import "./dynamic-radio-group.scss";

const DynamicRadioGroup = (props) => {
  const { label, accessor, options, direction } = props;

  return (
    <div className="dynamic-radio-group">
      <Field name={accessor}>
        {({ field, meta, form }) => (
          <>
            <Label className="font-weight-bold">{label}</Label>

            <FormGroup check>
              <div>
                <div
                  className={
                    direction === "horizontal"
                      ? "d-flex flex-row"
                      : "d-flex flex-column"
                  }
                >
                  {options.map((option) => {
                    return (
                      <div
                        key={option.value}
                        className={
                          direction === "horizontal"
                            ? "d-flex flex-row radio-items"
                            : "d-flex flex-row"
                        }
                      >
                        <>
                          <Input
                            type="radio"
                            name={accessor}
                            checked={field.value === option.value}
                            onChange={() =>
                              form.setFieldValue(accessor, option.value)
                            }
                          />
                          <Label
                            className="option-label"
                            onClick={() =>
                              form.setFieldValue(accessor, option.value)
                            }
                          >
                            {option.label}
                          </Label>
                        </>
                      </div>
                    );
                  })}
                </div>
              </div>
            </FormGroup>
            <section className="mb-3 small error-text error-message">
              {meta.touched && meta.error && <div>{meta.error}</div>}
            </section>
          </>
        )}
      </Field>
    </div>
  );
};

export default DynamicRadioGroup;

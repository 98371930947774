export const citiwests = [
  { name: "tma.jpg" },
  { name: "highway-truck.jpg" },
  { name: "traffic-utes.jpg" },
  { name: "travel-times.jpeg" },
  { name: "arrow-board.jpg" },
  { name: "toilet.jpg" },
  { name: "portable-traffic-lights.jpg" },
  { name: "variable-speed-limit-signs.jpg" },
  { name: "truck-mounted-attenuators.jpg" },
  { name: "vms-boards.jpg" },
  { name: "vms-ute.jpg" },
  // { name: "tipper.jpg" },
  // { name: "dingo.jpg" },
  // { name: "fan.JPG" },
  // { name: "hoist.jpg" },
  // { name: "trailer.jpg" },
  // { name: "toilet.jpg" },
  // { name: "saw-2.jpg" },
  // { name: "saw.JPG" },
  // { name: "mower.jpg" },
  // { name: "toilet-2.jpg" },
  // { name: "diesel-heaters.jpg" },
  // { name: "cleaner.jpg" },
  // { name: "generator.jpg" },
  // { name: "vermeer.jpg" },
  // { name: "submersable-pump.jpg" },
];

import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import LoginService from "./../login/services/service";

const Logout = () => {
  useEffect(() => {
    const loginService = LoginService();
    loginService.logout();
    window.location.reload();

    localStorage.clear();
  }, []);

  return <Redirect to="/" />;
};

export default Logout;

import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isSuccessful: false
};

export default function GteQuoteReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_QUOTE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.GET_QUOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true
      };
    case ActionTypes.GET_QUOTE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false
      };
    case ActionTypes.GET_QUOTE_CLEAN_UP:
      return {
        ...state,
        isSuccessful: false
      };
    default:
      return state;
  }
}

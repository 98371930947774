const adminLinks = [
  {
    key: 0,
    title: "Manage Users",
    icon: "fal fa-user-alt",
    isFunc: false,
    href: "/admin/manage-users/",
    clickFunc: null,
  },
];

const getAdminLinks = () => {
  return adminLinks;
};

export default getAdminLinks;

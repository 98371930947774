import React, { useState } from "react";
import useInitialiseForm from "./hooks/initialise-form";
import EnhancedContentSection from "./../../../../../components/common/enhanced-content-section";
import { Form } from "formik";
import { Row, Col } from "reactstrap";
import CommonField from "./../../../../../components/common/common-field";
import Password from "./../../../../../components/common/password-component";
import FormikSubmitButton from "./../../../../../components/common/formik-submit-button";
import Select from "./../../../../../components/common/select";
import MaskedInput from "./../../../../../components/common/masked-input";
import userTypes from "./../../../../../constants/user-types";
import LinksComponent from "./../../../admin-view/links-component";
import withAdminHoc from "./../../../with-admin-hoc/with-admin-hoc";

const CreateUpdateUserPage = ({
  isLoading,
  memberType,
  match,
  cleanUp,
  getUserDetails,
  user,
  setValues,
  values,
  isFetching,
}) => {
  let [isEdit, setIsEdit] = useState(false);

  useInitialiseForm(
    match.params.id,
    isEdit,
    getUserDetails,
    cleanUp,
    setIsEdit,
    user,
    setValues,
    isFetching
  );

  let component = (
    <div className="mb-5">
      {!isFetching && (
        <EnhancedContentSection isBlocking={isLoading}>
          <div className="my-2">
            <LinksComponent
              pageTitle={`${isEdit ? "Update" : "Create"}  a User`}
            />
          </div>

          <Form className="flex-fill mt-4">
            <Row>
              <Col xs="12" lg="9">
                <Select
                  label="User Type"
                  accessor="memberType"
                  showLabel
                  options={userTypes}
                  value={values["memberType"]}
                  closeMenuOnSelect={true}
                />

                <Row form>
                  <Col md={6}>
                    <CommonField
                      label="First Name"
                      accessor="firstName"
                      showLabel
                      type="text"
                    />
                  </Col>
                  <Col md={6}>
                    <CommonField
                      label="Last Name"
                      accessor="lastName"
                      showLabel
                      type="text"
                    />
                  </Col>
                </Row>

                <CommonField
                  label="Email"
                  accessor="email"
                  showLabel
                  type="text"
                />

                <MaskedInput
                  label={`Mobile`}
                  accessor={`mobile`}
                  mask="0\499 999 999"
                  showLabel
                />

                {!isEdit && <Password />}

                <div className="mt-3">
                  <FormikSubmitButton
                    label="Submit"
                    className="sw-btn-primary"
                    isLoading={isLoading}
                    scrollToFirstError={true}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </EnhancedContentSection>
      )}
    </div>
  );

  return withAdminHoc(component, memberType);
};

export default CreateUpdateUserPage;

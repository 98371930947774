import React from "react";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import TitleHeader from "./../../../components/common/title-header";
import PageTagging from "components/common/page-tagging/page-tagging";

const VariableSpeedLimitSigns = () => {
  let pageTitle = "Variable Speed Limit Signs";

  return (
    <>
      <TitleHeader title={pageTitle} />

      <EnhancedContentSection
        isBlocking={false}
        showPageTitle={false}
        pageTitle={pageTitle}
      >
        <PageTagging
          title="Mr Traffic Equipment Hire | Variable Speed Limit Signs Melbourne"
          description="Mr Traffic Equipment Hire. Melbourne's leading Variable Speed Limit Signs (VSLS) Hire"
        />
        <div className="mt-3 services-default">
          <img
            src="/images/variable-speed-limit-signs.jpg"
            alt="Variable Speed Limit Signs VSLS"
            className="image shadow"
          />

          <div className="mt-3 text-area">
            <h5>Improve Site Safety with our VSLS</h5>
            <p>
              Our Variable Speed Limit Signs (VSLS) are ideal for any worksite
              requiring temporary speed limit signs, and are proven effective in
              reducing speeds approaching roadworks and temporary speed
              restricted areas.
            </p>
            <p>
              Our VSLS employ the latest technology with full remote control and
              data recording and are compatible with VicRoads STREAMS.
            </p>

            <p className="mt-3">
              <h5>Features</h5>

              <ul className="mt-3">
                <li>Easy operation</li>
                <li>Fully remote control</li>
                <li>Anti theft and tamper protection</li>
                <li>Solar pwered / Battery operated</li>
                <li>VicRoads STREAMS compatible</li>
                <li>GPS tracking</li>
              </ul>
            </p>
          </div>
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default VariableSpeedLimitSigns;
